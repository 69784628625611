<template>
    <v-main>
    <v-card width="1200px" class="mt-5 mx-auto" color="#e21e81" outlined>
        <v-card-title style="color:white" class="center">
            Select date range to find and follow up service providers by their signup date
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row justify="center" align="center">
                    <v-col mx-auto>
                        <v-menu
                            v-model="menuStart"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startDate"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dark
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="startDate"
                            @input="menuStart = false"
                            color="#e21e81"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col mx-auto>
                        <v-menu
                            v-model="menuEnd"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endDate"
                                label="End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dark
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="endDate"
                            @input="menuEnd = false"
                            color="#e21e81"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn elevation="4" :disabled="!oneWeekRange"
            @click="pullFollowUpReport">
                <v-icon color="#e21e81">
                    mdi-briefcase-search
                </v-icon></v-btn>
        </v-card-actions>
        <v-card-text v-if="!oneWeekRange" style="color:gold">
          <v-row align="center" justify="center">
                  <v-icon color="amber">mdi-alert</v-icon>
                  Select a date range of 7 days maximum
                </v-row>
        </v-card-text>
        <v-main v-if="queryInProgress">
          <v-layout text-center wrap>
              <v-flex>
                <span style="color: lime; font-size:20px" >
                  <v-icon x-large color="amber">mdi-information-outline</v-icon>
                    Service provider followup search in progress
                </span>
                <v-progress-circular
                  :size="40"
                  :width="7"
                  color="amber"
                  indeterminate
                ></v-progress-circular>
              </v-flex>
          </v-layout>
        </v-main>
        <v-data-table
        v-if="followUpData != null"
        :headers="headers"
        :items="followUpData"
        sort-by="calories"
        class="elevation-1">
        <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Service Providers for Follow Up</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="headline"> Follow Up Engagement</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                  <v-row align="center" justify="center" style="color:#E21E81">
                  Follow Up Actions
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                  <v-checkbox
                    v-model="phoneCallAction"
                    label="Phone Call"
                    color="#e21e81"
                  >
                  </v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                  <v-checkbox
                    v-model="smsAction"
                    label="SMS Text"
                    color="#e21e81"
                  >
                  </v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                  <v-checkbox
                    v-model="telegramAction"
                    label="Telegram Channel"
                    color="#e21e81"
                  >
                  </v-checkbox>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" style="color:#E21E81">
                  Follow Up Notes
                </v-row>
                <v-row>
                  <v-col
                  >
                    <v-text-field
                      color="#E21E81"
                      v-model="followUpNotes"
                      label="Follow up notes"
                      maxLength="80"
                      counter="80"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-text v-if="updatingLastFollowUpTimeStamp != null">
               <v-row align="center" justify="center" style="color:#E21E81">
                  Previous Follow Up Note
                </v-row>
             {{ retroFollowUpNote }} - {{ retroFollowUpTimeStamp }} - {{ retroFollowUpAgent }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#E21E81"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="#E21E81"
                text
                @click="update"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
        <template v-slot:item.actions="{ item }">
      <v-icon
        small
        color="#E21E81"
        class="mr-2"
        @click="logFollowUp(item)"
      >
        mdi-reminder
      </v-icon>
    </template>
        </v-data-table>
    </v-card>
    </v-main>
</template>

<script>
import firebase, { firestore } from 'firebase/app';

export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      updatingServiceProvider: null,
      updatingFollowUpLogs: null,
      updatingLastFollowUpTimeStamp: null,
      indexOfupdatedFollowUp: null,
      retroFollowUpNote: null,
      retroFollowUpTimeStamp: null,
      retroFollowUpAgent: null,
      phoneCallAction: false,
      smsAction: false,
      telegramAction: false,
      followUpNotes: null,
      menuStart: false,
      menuEnd: false,
      queryInProgress: false,
      followUpData: null,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Phone Number', value: 'phoneNumber' },
        { text: 'Verified', value: 'verified' },
        { text: 'Published Ad', value: 'publishedAd' },
        { text: 'Joined On', value: 'createDate' },
        { text: 'Last Follow Up', value: 'lastFollowUp' },
        { text: 'Phone Call', value: 'phoneCall' },
        { text: 'SMS', value: 'sms' },
        { text: 'Telegram', value: 'telegram' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    validDatesSelected() {
      return this.startDate != null && this.endDate != null
      && (this.startDate < this.endDate);
    },
    oneWeekRange() {
      const startTime = new Date(String(this.startDate)).getTime();
      // console.log('start time', startTime);
      const endTime = new Date(String(this.endDate)).getTime();
      // console.log('end time', endTime);
      return this.validDatesSelected && endTime - startTime < 691200000;
    },
    gdoAgent() {
      return this.$store.getters['auth/email'];
    },
  },
  methods: {
    update() {
      const currentLogs = this.updatingFollowUpLogs;
      const db = firebase.firestore();
      const timeNow = new Date();
      currentLogs.push({
        note: this.followUpNotes,
        phoneCall: this.phoneCallAction,
        sms: this.smsAction,
        telegram: this.telegramAction,
        gdoAgent: this.gdoAgent,
        timeStamp: firestore.Timestamp.fromDate(timeNow),
      });
      db.collection('users')
        .doc(this.updatingServiceProvider)
        .set({
          supportFollowUpLog: {
            lastFollowUp: firestore.Timestamp.fromDate(timeNow),
            logs: currentLogs,
          },
        }, { merge: true })
        .then(() => {
          if (this.indexOfupdatedFollowUp > -1) {
            this.followUpData.splice(this.indexOfupdatedFollowUp, 1);
          }
          this.close();
        })
        .catch((error) => console.log(error));
    },
    close() {
      this.dialog = false;
      this.followUpNotes = null;
      this.phoneCallAction = false;
      this.smsAction = false;
      this.telegramAction = false;
      this.retroFollowUpAgent = null;
      this.retroFollowUpTimeStamp = null;
    },
    logFollowUp(item) {
      // clear data
      this.retroFollowUpNote = null;
      this.phoneCallAction = false;
      this.smsAction = false;
      this.telegramAction = false;
      this.retroFollowUpAgent = null;
      this.retroFollowUpTimeStamp = null;
      // console.log(item);
      if (item.lastFollowUp !== '-') {
        this.retroFollowUpNote = item.followUpLogs[0].note;
        this.retroFollowUpTimeStamp = item.lastFollowUp;
        this.retroFollowUpAgent = item.followUpLogs[0].gdoAgent;
      }
      this.updatingServiceProvider = item.serviceProvider;
      this.updatingLastFollowUpTimeStamp = item.lastFollowUp;
      this.updatingFollowUpLogs = item.followUpLogs == null ? [] : item.followUpLogs;
      this.indexOfupdatedFollowUp = this.followUpData.indexOf(item);
      this.dialog = true;
    },
    dateFormat(date) {
      const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      // eslint-disable-next-line prefer-template
      return (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + '-' + y;
    },
    pullFollowUpReport() {
      const userList = [];
      const followUpList = [];
      const startTime = new Date(String(this.startDate)).getTime();
      // console.log('query start time', startTime);
      const endTime = new Date(String(this.endDate)).getTime();
      // console.log('query end time', endTime);
      const db = firebase.firestore();
      this.queryInProgress = true;
      db.collection('users')
        .where('createdAt', '>', firestore.Timestamp.fromMillis(startTime))
        .where('createdAt', '<', firestore.Timestamp.fromMillis(endTime))
        // .limit(50)
        .get()
        .then((userSnapShot) => {
          // console.log('found some');
          // eslint-disable-next-line max-len
          userSnapShot.forEach((user) => {
            if (user.data().serviceProvider === true) {
              userList.push(user);
              // console.log(userList);
            }
          });
        })
        .then(() => {
          userList.forEach((user) => {
            db.collection('goodays')
              .where('phoneNumber', '==', user.data().phoneNumber)
              .get()
              .then((goodaySnapShot) => {
                goodaySnapShot.forEach((gooday) => {
                  // console.log(user.createdAt);
                  if (gooday.data().approvedUser === false
                    || user.data().verifiedUser === false
                    || gooday.data().services === null
                    || gooday.data().services === []) {
                    if (user.data().supportFollowUpLog != null) {
                      if (user.data().supportFollowUpLog.logs.length < 2) {
                        followUpList.push({
                          firstName: user.data().firstName,
                          lastName: user.data().lastName,
                          phoneNumber: user.data().phoneNumber,
                          serviceProvider: user.id,
                          verified: user.data().verifiedUser === true ? 'yes' : 'no',
                          publishedAd: (gooday.data().services == null || gooday.data().services === []) ? 'no' : 'yes',
                          createDate: this.dateFormat(user.data().createdAt),
                          lastFollowUp:
                            this.dateFormat(user.data().supportFollowUpLog.lastFollowUp),
                          phoneCall: user.data().supportFollowUpLog.logs[0].phoneCall ? 'yes' : 'no',
                          sms: user.data().supportFollowUpLog.logs[0].sms ? 'yes' : 'no',
                          telegram: user.data().supportFollowUpLog.logs[0].telegram ? 'yes' : 'no',
                          followUpLogs: user.data().supportFollowUpLog.logs,
                        });
                      }
                    } else {
                      followUpList.push({
                        firstName: user.data().firstName,
                        lastName: user.data().lastName,
                        phoneNumber: user.data().phoneNumber,
                        serviceProvider: user.id,
                        verified: user.data().verifiedUser === true ? 'yes' : 'no',
                        publishedAd: (gooday.data().services == null || gooday.data().services === []) ? 'no' : 'yes',
                        createDate: this.dateFormat(user.data().createdAt),
                        lastFollowUp: '-',
                        phoneCall: '-',
                        sms: '-',
                        telegram: '-',
                        followUpLogs: null,
                      });
                    }
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          });
          this.followUpData = followUpList;
          this.queryInProgress = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.$store.dispatch('auth/persistSession');
  },
};

</script>

<style>
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
