<template>
  <v-main>
    <v-card width="100%" class="mt-5 mx-auto" color="#e21e81" outlined>
      <v-card-title style="color: white" class="center">
        Outstanding Customer Complaints
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn elevation="4" @click="pullCompliantReport">
          <v-icon color="#e21e81"> mdi-briefcase-search </v-icon>
        </v-btn>
      </v-card-actions>
      <v-data-table
        v-if="reportsData != null"
        :headers="headers"
        :items="reportsData"
        sort-by="reportDate"
        class="elevation-1"
      >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Complaints from Regular Users</v-toolbar-title>
          <v-divider>
            class="mx-4"
            inset
            vertical
          </v-divider>
          <v-spacer></v-spacer>
          <v-dialog
          v-model="dialog"
          max-width="800px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">Customer Complaint</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row align="start" justify="start" style="color:#E21E81" class="mb-4">
                    <span >{{ reportDate }}</span>
                  </v-row>
                  <v-row align="start" justify="start" style="color:#E21E81" class="mb-4">
                    <span class="headline">Reported By</span>
                  </v-row>
                  <v-row class="mb-6">
{{ reporterFirstName }} {{ reporterLastName }}  |  {{ reporterPhoneNumber }}  |  Regular User
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row align="start" justify="start" style="color:#E21E81" class="mb-4">
                    <span class="headline">Reported On</span>
                  </v-row>
                  <v-row class="mb-6">
{{ accusedFirstName }} {{ accusedLastName }}  |  {{ accusedPhoneNumber }}  |  Service Provider
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row align="start" justify="start" style="color:#E21E81">
                    <span class="headline">Reported Services</span>
                  </v-row>
                  <v-row align="start" justify="start">
                    <v-col v-for="(item, i) in compliantServices" :key="i">
                      <v-card height="50" width="150" color="#0288D1" dark>
                        <v-container>
                           <v-row align="center" justify="center">
                             {{ item }}
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align="start" justify="start" style="color:#E21E81" class="mt-4">
                    <span class="headline">Report Reasons</span>
                  </v-row>
                  <v-row align="start" justify="space-around">
                    <v-col v-for="(item, i) in complaintReasons" :key="i">
                       <v-card height="50" width="150" color="#FF6F00" dark>
                         <v-container>
                           <v-row align="center" justify="center">
                             {{ item }}
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="complaintDetail !== null" align="start"
                   justify="start" style="color:#E21E81" class="mt-4">
                    <span class="headline">Report Details</span>
                  </v-row>
                  <v-row>
                    {{ complaintDetail }}
                  </v-row>
                  <!-- <v-row v-if="complaintDetail !== null" align="start"
                   justify="start" style="color:#E21E81" class="mt-4">
                    <span class="headline">Report Details</span>
                  </v-row>
                  <v-row>
                    {{ complaintDetail }}
                  </v-row> -->
                   <v-row v-if="otherComplaints.legth !== 0" align="start" justify="start"
                    style="color:#E21E81" class="mt-4">
                    <span class="headline">Other Compliants Against Provider</span>
                  </v-row>
                  <v-row align="start" justify="space-around">
                    <v-col v-for="(item, i) in otherComplaints" :key="i">
                       <v-card v-if="item.resolved === 'Not Resolved'"
                        width="350" color= "#E21E90" dark>
                         <v-container ml-4>
                           <v-row>Date          : {{ item.reportDate }}</v-row>
                           <v-row>Reported By   : {{ item.reporterFirstName }}
                              {{ item.reporterLastName }}</v-row>
                           <v-row>Phone Number  : {{ item.reporterPhoneNumber }}</v-row>
                            <v-row>Report Reasons: {{ item.reportReasons }}</v-row>
                             <v-row>Report Notes  : {{ item.reportDetail }}</v-row>
                             <v-row>Status      : {{ item.resolved }}</v-row>
                        </v-container>
                      </v-card>
                      <v-card v-else width="350" dark>
                         <v-container ml-4>
                           <v-row>Date          : {{ item.reportDate }}</v-row>
                           <v-row>Reported By   : {{ item.reporterFirstName }}
                              {{ item.reporterLastName }}</v-row>
                           <v-row>Phone Number  : {{ item.reporterPhoneNumber }}</v-row>
                            <v-row>Report Reasons: {{ item.reportReasons }}</v-row>
                             <v-row>Report Notes  : {{ item.reportDetail }}</v-row>
                             <v-row>Status      : {{ item.resolved }}</v-row>
                             <v-row>Resolution Note      : {{ item.resolutionNote }}</v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <v-row align="start" justify="start" style="color:#E21E81">
                    <span class="headline">Current Ad Services</span>
                  </v-row>
                  <v-row align="start" justify="start">
                    <v-col v-for="(item, i) in currentServices" :key="i">
                      <v-card height="50" width="150" color="#E21E81" dark>
                        <v-container>
                           <v-row align="center" justify="center">
                             {{ item }}
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row  align="start"
                   justify="start" style="color:#E21E81" class="mt-4 mb-4">
                    <span class="headline">Current Ad Summary</span>
                  </v-row>
                  <v-row>
                    {{ currentAdSummary }}
                  </v-row>
                  <v-row align="center" justify="center" style="color:#E21E81" class="mt-4">
                    <span class="headline">Resolution Note</span>
                </v-row>
                <v-row>
                  <v-col
                  >
                    <v-text-field
                      color="#E21E81"
                      v-model="resolutionNote"
                      label="Customer compliant resolution note"
                      maxLength="200"
                      counter="200"
                    ></v-text-field>
                  </v-col>
                </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#E21E81"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!resolutionInProgress"
                color="#E21E81"
                text
                @click="resolve"
              >
                Resolve
              </v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
      <v-icon
        small
        color="#E21E81"
        class="mr-2"
        @click="mediateCompliant(item)"
      >
        mdi-reminder
      </v-icon>
    </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import firebase, { firestore } from 'firebase/app';

export default {
  data() {
    return {
      dialog: false,
      reportsData: null,
      reporterFirstName: null,
      reporterLastName: null,
      reporterPhoneNumber: null,
      accusedFirstName: null,
      accusedLastName: null,
      accusedPhoneNumber: null,
      compliantServices: null,
      complaintReasons: null,
      complaintDetail: null,
      resolutionNote: null,
      compliantResolved: null,
      compliantReporterId: null,
      compliantReportedId: null,
      reportDate: null,
      currentAdSummary: null,
      currentServices: null,
      otherComplaints: [],
      indexOfComplaint: null,
      resolutionInProgress: false,
      headers: [
        {
          text: 'Report Date',
          align: 'start',
          sortable: true,
          value: 'reportDate',
        },
        { text: 'First Name (Filer)', value: 'reporterFirstName' },
        { text: 'Last Name (Filer)', value: 'reporterLastName' },
        { text: 'Filer Phone Number', value: 'reporterPhoneNumber' },
        { text: 'First Name (Accused)', value: 'accusedFirstName' },
        { text: 'Last Name (Accused)', value: 'accusedLastName' },
        { text: 'Accused Phone Number', value: 'accusedPhoneNumber' },
        { text: 'Services', value: 'services' },
        { text: 'Complaints', value: 'complaintReasons' },
        // { text: 'Complaint Detail', value: 'complaintDetail' },
        // { text: 'Report Date', value: 'reportDate' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    dateFormat(date) {
      const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      // eslint-disable-next-line prefer-template
      return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    },
    mediateCompliant(item) {
      this.reporterFirstName = item.reporterFirstName;
      this.reporterLastName = item.reporterLastName;
      this.reporterPhoneNumber = item.reporterPhoneNumber;
      this.accusedFirstName = item.accusedFirstName;
      this.accusedLastName = item.accusedLastName;
      this.accusedPhoneNumber = item.accusedPhoneNumber;
      this.compliantServices = item.services;
      this.complaintReasons = item.complaintReasons;
      this.complaintDetail = item.complaintDetail;
      this.resolutionNote = item.resolutionNote;
      this.compliantResolved = item.compliantResolved;
      this.reportDate = item.reportDate;
      this.compliantReporterId = item.compliantReporterId;
      this.compliantReportedId = item.compliantReportedId;
      this.currentServices = item.currentServices;
      this.currentAdSummary = item.currentAdSummary;
      this.indexOfComplaint = this.reportsData.indexOf(item);
      this.dialog = true;
      // pull other reports against provider
      this.providerReports(this.compliantReportedId, this.compliantReporterId);
    },
    pullCompliantReport() {
      const actionableReports = [];
      const db = firebase.firestore();
      db.collectionGroup('reports')
        .where('resolved', '!=', true)
        .get()
        .then((reportsSnapShot) => {
          console.log(reportsSnapShot);
          reportsSnapShot.forEach((report) => {
            console.log(report.ref.path.split('/')[1]);
            const spId = report.ref.path.split('/')[1];
            if (spId !== report.data().reporterId) {
              db.collection('goodays').doc(spId).get()
                .then((providerRef) => {
                  db.collection('users').doc(report.data().reporterId).get()
                    .then((reporterRef) => {
                      // if (report.data().resolved == null) {
                      //   db.collection('reports').doc(spId).collection('reports')
                      //     .doc(report.data().reporterId)
                      //     .set({
                      //       resolved: false,
                      //     }, { merge: true })
                      //     .then(() => {
                      //     })
                      //     .catch();
                      // }
                      actionableReports.push({
                        reporterFirstName: reporterRef.data().firstName,
                        reporterLastName: reporterRef.data().lastName,
                        reporterPhoneNumber: reporterRef.data().phoneNumber,
                        accusedFirstName: providerRef.data().firstName,
                        accusedLastName: providerRef.data().lastName,
                        accusedPhoneNumber: providerRef.data().phoneNumber,
                        services: report.data().services,
                        currentServices: providerRef.data().services,
                        currentAdSummary: providerRef.data().summary,
                        complaintReasons: report.data().reportReasons,
                        complaintDetail: report.data().reportDetail,
                        resolutionNote: report.data().resolutionNote,
                        compliantResolved: report.data().compliantResolved,
                        reportDate: this.dateFormat(report.data().timeStamp),
                        compliantReporterId: report.data().reporterId,
                        compliantReportedId: spId,
                      });
                      this.reportsData = actionableReports;
                    })
                    .catch((error) => console.log(error));
                })
                .catch((error) => console.log(error));
            }
          });
        })
        .catch((error) => console.log(error));
    },
    resolve() {
      this.resolutionInProgress = true;
      const db = firebase.firestore();
      const timeNow = new Date();
      db.collection('reports').doc(this.compliantReportedId)
        .collection('reports').doc(this.compliantReporterId)
        .set({
          resolved: true,
          resolutionTime: firestore.Timestamp.fromDate(timeNow),
          resolutionNote: this.resolutionNote,
          resolvedBy: firebase.auth().currentUser.uid,
        }, { merge: true })
        .then(
          () => {
            if (this.indexOfComplaint > -1) {
              this.reportsData.splice(this.indexOfComplaint, 1);
            }
            this.resolutionInProgress = false;
            this.close();
            this.resolutionNote = null;
          },
        )
        .catch((error) => console.log(error));
    },
    close() {
      this.dialog = false;
    },
    providerReports(providerId, currentReporterId) {
      const db = firebase.firestore();
      const reportsList = [];
      db.collection('reports').doc(providerId)
        .collection('reports').get()
        .then((reportsSnapShot) => {
          reportsSnapShot.forEach((reportref) => {
            if (reportref.data().reporterId !== currentReporterId) {
              db.collection('users').doc(reportref.data().reporterId)
                .get()
                .then((userRef) => {
                  reportsList.push({
                    // eslint-disable-next-line no-template-curly-in-string
                    reporterFirstName: userRef.data().firstName,
                    reporterLastName: userRef.data().lastName,
                    reporterPhoneNumber: userRef.data().phoneNumber,
                    reportDate: this.dateFormat(reportref.data().timeStamp),
                    resolved: reportref.data().resolved ? 'Resolved' : 'Not Resolved',
                    reportDetail: reportref.data().reportDetail,
                    resolutionNote: reportref.data().resolutionNote,
                    reportReasons: reportref.data().reportReasons.join(','),
                  });
                  console.log(reportsList);
                })
                .catch((error) => console.log(error));
            }
          });
          this.otherComplaints = reportsList;
        })
        .catch((error) => console.log(error));
    },
    // regularUserReports(regularUserId) {},
  },
};
</script>
