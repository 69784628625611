<template>
  <v-main>
    <v-row v-if="!smsSendingInProgress">
      <v-col>
        <v-card
          width="600px"
          height="300px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          elevation="10"
        >
          <v-card-title class="pb-0">
            <h3 style="color: white">Customer Lookup</h3>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-form>
              <v-text-field
                label="calling customer phone number"
                prepend-icon="mdi-cellphone"
                v-model="phoneNumber"
                dark
                :error-messages="phoneNumberErrors()"
                required
                @input="$v.phoneNumber.$touch()"
                @blur="$v.phoneNumber.$touch()"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              class="mr-10"
              :disabled="$v.phoneNumber.$invalid"
              dark
              @click="customerLookUp"
            >
              <v-icon color="#E21E81">mdi-account-search</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          width="800px"
          height="300px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          v-if="customerLookedUp"
          elevation="10"
        >
          <v-card-title class="pb-0">
            <h3 style="color: white">Customer Information</h3>
          </v-card-title>
          <v-card-text v-if="customerLookedUp">
            <v-form v-model="formValid">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="customerFirstName"
                      :rules="nameRules"
                      :counter="10"
                      label="First Name"
                      required
                      dark
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="customerLastName"
                      :rules="nameRules"
                      :counter="10"
                      label="Last Name"
                      required
                      dark
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerHouseHoldList"
                      v-model="customerHouseHold"
                      label="Size of Household"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerResidenceList"
                      v-model="customerResidence"
                      label="Residence Type"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerResidenceOwnershipList"
                      v-model="customerResidenceOwnership"
                      label="Home Owner"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerReligionPreferenceList"
                      v-model="customerReligionPreference"
                      label="Religion Preference"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-if="callCount > 0">
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <h4 style="color: white">previous calls</h4>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                     <h1 style="color: white">{{ callCount }}</h1>
                  </v-col>
                  <v-col
                    v-if="appUserCustomer"
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <v-icon color="#e21e81">mdi-cellphone-play</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-else>
                  <v-col
                    v-if="appUserCustomer"
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <v-icon color="#e21e81">mdi-cellphone-play</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-if="callCount > 0">
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <h4 style="color: white">last request location</h4>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                     <h4 style="color: white">
                       {{ lastRequestLocation }}
                     </h4>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <!-- <v-card-actions class="justify-center">
            <v-btn v-if="newCustomer" dense>Submit</v-btn>
            <v-btn v-else dense>Update</v-btn>
          </v-card-actions> -->
          </v-card>
          </v-col>
          <v-col>
            <v-card
          width="1000px"
          height="400px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          v-if="!newCustomer && customerLookedUp && lastRequestProviders && !followupIsDone"
          elevation="10"
        >
          <v-card-title class="pb-0">
            <h3 style="color: white">Previous Request Follow Up</h3>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-data-table
              hide-default-footer
              dark
              dense
              :items="lastRequestData"
              :headers="customerInfoHeaders"
              item-key="lastRequestLocation"
            ></v-data-table>
            <v-row align="center" class="pa-0" no-gutters>
              <h3 class="my-1 mr-2" style="color: white">
                Did you hire gig workers in the previous request call ?</h3>
                <v-checkbox
                  color="#e21e81"
                  v-model="previousCallSuccess"
                  dark
                />
            </v-row>
            <v-container v-if="previousCallSuccess">
              <v-row align="center" class="pa-0" no-gutters>
                <v-select
                  :items="lastProviderNameList"
                  @change="selectHiredProvider"
                  label="Provider hired in previous request"
                  dense
                  dark
                  outlined
                  class="mx-2"
                />
                <v-text-field
                  label="Paid Amount"
                  dense
                  outlined
                  dark
                  :rules="numberRules"
                  v-model="lastRequestPaidAmount"
                />
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
              <v-text-field
                dense
                dark
                class="mx-2"
                label="Note"
                v-model="lastRequestFollowupNote"
              />
            </v-row>
            </v-container>
            <v-container v-else>
              <v-row>
                <v-col
                  class="d-flex"
                  cols="12"
                  md="4">
                    <v-select
                      v-model="lastRequestFailReasons"
                      :items="hireFailureReasons"
                      label="Select reasons for not hiring"
                      multiple
                      hint="Reasons why customer did not hire"
                      item-color="#e21e81"
                      persistent-hint
                      dense
                      dark
                    />
                  </v-col>
                <v-col
                  class="d-flex"
                  cols="12"
                  md="8"
                >
                  <v-text-field
                    label="Customer comments or feeback"
                    v-model="lastRequestFailReasonNotes"
                    dense
                    outlined
                    dark
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions dense class="justify-center">
            <v-btn :disabled="!readyForFollowup" @click="updateCustomerLog">update</v-btn>
          </v-card-actions>
            </v-card>
          </v-col>
    </v-row>
    <v-divider class="mt-2 mb-2" color="white"></v-divider>
    <v-row v-if="followupIsDone && readyForCustomerInfoUpdate && !smsSendingInProgress">
      <v-col
        ><v-card
          width="600px"
          height="450px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          elevation="10"
        >
          <v-card-title class="pb-0">
            <h3 style="color: white">Query</h3>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="center" class="pa-0" no-gutters>
                <v-col dense>
                  <v-menu :rounded="true" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        color="#e21e81"
                        width="200px"
                        class="white--text my-1 py-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Select Category
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="c in services" :key="c.category" link>
                        <v-list-item-title
                          v-text="c.category"
                          @click="selectCategory(c.category)"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col dense>
                  <v-card color="lime" width="280px">
                    <v-card-text class="my-1 py-2">
                      {{ queryCategory }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <v-col>
                  <v-menu :rounded="true" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        color="#e21e81"
                        width="200px"
                        class="white--text my-1 py-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Select Service
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="s in queryServiceList" :key="s" link>
                        <v-list-item-title
                          v-text="s"
                          @click="selectService(s)"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col dense>
                  <v-card color="lime" width="280px">
                    <v-card-text class="my-1 py-2">
                      {{ queryService }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-0 mt-4" no-gutters>
                <v-col
                    class="d-flex mr-10"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerHireDemandList"
                      v-model="customerHireDemand"
                      label="Hiring Type"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="mr-10">
                    <v-text-field
                      v-model="customerBudgetMin"
                      :rules="numberRules"
                      label="Budget Min"
                      required
                      dark
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3">
                    <v-text-field
                      v-model="customerBudgetMax"
                      :rules="maxRules"
                      label="Budget Max"
                      required
                      dense
                      dark
                    ></v-text-field>
                  </v-col>
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <v-col dense>
                  <v-card color="lime" width="200px">
                    <v-card-text class="my-1 py-2">
                      Customer Location
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col dense>
                  <v-card color="#e21e81" width="280px">
                    <v-card-text class="my-1 py-2">
                      <VueGooglePlaces
                        id="map"
                        :options="{ fields: ['geometry'] }"
                        types="establishment"
                        country="et"
                        @placechanged="getAddressData"
                        style="width: 100%"
                      >
                        <v-text-field
                          dense
                          dark
                          hide-details=""
                          class="ma-0 pt-1"
                          label="Enter a location"
                        />
                      </VueGooglePlaces>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <v-col dense>
                  <v-card color="lime" width="200px">
                    <v-card-text class="my-1 py-2">
                      Search Radius (kms)
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-slider
                    class="pt-10"
                    width="280px"
                    v-model="queryRadius"
                    label="Distance (km)"
                    thumb-color="#e21e81"
                    thumb-label="always"
                    :min="queryMinRadius"
                    :max="queryMaxRadius"
                    dark
                  ></v-slider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center pa-0">
            <v-btn :disabled="!readyForQuery" @click="searchProviders">
              <v-icon color="#E21E81">mdi-account-search</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="space-around" v-if="followupIsDone && readyForCustomerInfoUpdate
     && !smsSendingInProgress">
      <v-col>
        <v-card
          width="900px"
          height="500px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          elevation="10"
        >
          <GmapMap
            :center="center"
            :zoom="13"
            map-type-id="roadmap"
            style="width: 100%; height: 500px"
          >
            <GmapMarker
              :key="center.lat"
              :position="center"
              :clickable="true"
              :draggable="false"
              :icon="{ url: require('../assets/provider_icon.png') }"
              @click="center = center"
            />
            <GmapCircle
              :center="center"
              :radius="queryRadiusMeters"
              :visible="true"
              :options="{
                fillColor: '#e81e21',
                fillOpacity: 0.2,
                strokeColor: '#e81e21',
                strokeWidth: 1.0,
              }"
            ></GmapCircle>
            <GmapMarker
              v-for="(m, index) in queryResultTableItems"
              :key="index"
              :position="m.pos"
              :clickable="true"
              :draggable="false"
              :icon="{ url: require('../assets/user_icon.png') }"
              @click="center = m.pos"
            />
          </GmapMap>
        </v-card>
      </v-col>
      <v-col
        ><v-card
          width="900px"
          height="500px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          elevation="10"
        >
          <v-card-title class="pb-0">
            <h3 style="color: white">Search Result</h3>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text v-if="noProvidersFoundNearby">
             <h4 style="color: white">No Providers Found! Search wider ...</h4>
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
              v-if="queryResultTableItems != []"
              height="280px"
              v-model="selectedProviders"
              :headers="queryResultTableHeaders"
              :items="queryResultTableItems"
              :single-select="queryResultSingleSelect"
              :items-per-page="resultItemsPerPage"
              show-select
              show-expand
              item-key="phoneNumber"
              class="elevation-2"
              dark
            >
              <template v-slot:item.verified="{ item }">
                <v-icon
                  :color="providerVerifiedIcon(item.verified).color"
                  small
                >
                  {{ providerVerifiedIcon(item.verified).icon }}
                </v-icon>
              </template>
              <template v-slot:item.prefered="{ item }">
                <v-icon
                  :color="providerPreferedIcon(item.prefered).color"
                  small
                >
                  {{ providerPreferedIcon(item.prefered).icon }}
                </v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  {{ item.ad }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="justify-center">
            <v-btn
              :disabled="!readyForDelivery"
              @click="fullfillRequest"
              class="mr-8"
            >
              <v-icon medium color="#E21E81">mdi-send</v-icon>
            </v-btn>
            <v-btn
              :disabled="!noProvidersFoundNearby"
              @click="fullfillRequest"
            >Close Request</v-btn>
          </v-card-actions>
        </v-card></v-col
      >
    </v-row>
    <v-row v-if="(!followupIsDone || !readyForCustomerInfoUpdate) && !smsSendingInProgress">
      <v-card
        width="900px"
        height="500px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10">
          <v-card-text>
            <h2 style="color: white" class="my-4 mx-auto">
              Call Center Procedure for Service Provider Requests</h2>
            <h3 style="color: white" class="my-4 mx-auto">
              1. Lookup calling customer's phone number using international format,
              i.e. starting with +251
            </h3>
            <h3 class="my-4 mx-auto" style="color: white">
              2. Greet customer and complete basic information about the customer.
            </h3>
            <h3 style="color: white" class="my-4 mx-auto">
              3. If the customer had previous request, complete the previous request
               followup section.
            </h3>
            <h3 style="color: white" class="my-4 mx-auto">
              4. Complete the Query section with the new request from the customer
               and complete the request.
            </h3>
          </v-card-text>
        </v-card>
    </v-row>
    <v-row v-if="smsSendingInProgress">
      <v-card
        width="900px"
        height="500px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10">
          <v-card-text>
            <h2 style="color: white" class="my-4 mx-auto">
              Automated SMS message sending in progress ...</h2>
          </v-card-text>
          <v-progress-linear
            indeterminate
            height="20"
            striped
            color="#e21e81"
          ></v-progress-linear>
        </v-card>
    </v-row>
    <v-divider dark></v-divider>
    <v-spacer></v-spacer>
  </v-main>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import firebase, { firestore } from 'firebase/app';
import * as geofire from 'geofire-common';
import axios from 'axios';

export default {
  data() {
    return {
      placesAccess: process.env.VUE_APP_PLACES_APIKEY,
      center: { lat: 9.005401, lng: 38.763611 },
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: 'poly',
      },
      phoneNumber: '+251',
      customerFirstName: '',
      customerLastName: '',
      customerLookupInProgress: false,
      customerLookedUp: false,
      appUserCustomer: false,
      nearbyLocation: null,
      newCustomer: false,
      calls: null,
      callCount: 0,
      lastRequestService: null,
      lastRequestDate: null,
      lastRequestProviders: [],
      lastHiredProvider: null,
      lastRequestLocation: null,
      userQueried: false,
      supplyResult: false,
      services: null,
      queryCategory: null,
      queryServiceList: [],
      queryService: null,
      queryLocation: null,
      queryMinRadius: 1,
      queryMaxRadius: 5,
      queryRadius: 1,
      queryResult: null,
      noProvidersFoundNearby: false,
      queryResultSingleSelect: false,
      queryResultTableHeaders: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'LastName', value: 'lastName' },
        { text: 'Prefered', value: 'prefered', default: false },
        { text: 'Verified', value: 'verified' },
        { text: 'Phone', value: 'phoneNumber' },
        { text: 'Services', value: 'services' },
        { text: 'Distance(km)', value: 'distance' },
      ],
      selectedProviders: [],
      queryResultTableItems: [],
      resultItemsPerPage: 5,
      lastRequestData: [],
      customerInfoHeaders: [
        {
          text: 'Last Request',
          value: 'lastRequestedService',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Request Location',
          value: 'lastRequestLocation',
        },
        {
          text: 'Date',
          value: 'lastRequestDate',
        },
        {
          text: 'Request Budget Min',
          value: 'customerBudgetMin',
        },
        {
          text: 'Request Budget Max',
          value: 'customerBudgetMax',
        },
        {
          text: 'App User',
          value: 'appUser',
        },
      ],
      formValid: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      numberRules: [
        (v) => !!v || 'Budget is required',
        // eslint-disable-next-line no-restricted-globals
        (v) => (!isNaN(parseFloat(v)) && v >= 0) || 'Budget should be number',
      ],
      maxRules: [
        (v) => !!v || 'Budget is required',
        // eslint-disable-next-line no-restricted-globals
        (v) => (!isNaN(parseFloat(v)) && v >= 0) || 'Budget should be number',
        (v) => (v >= 0 && parseFloat(this.customerBudgetMax) >= parseFloat(this.customerBudgetMin)) || 'Max should be greater than Min',
      ],
      paidRules: [
        (v) => !!v || 'Paid amount is required',
        // eslint-disable-next-line no-restricted-globals
        (v) => (!isNaN(parseFloat(v)) && v >= 0) || 'Paid amount should be number',
      ],
      customerHouseHoldList: ['Single', 'Couple', 'Family', 'undisclosed'],
      customerResidenceList: ['Private Compound', 'Condominium', 'Apartment', 'undisclosed'],
      customerResidenceOwnershipList: ['Rent', 'Own', 'undisclosed'],
      customerHireDemandList: ['any', 'monthly (resident)', 'monthly (outcall)', 'weekly', 'daily', 'hourly', 'task based'],
      customerReligionPreferenceList: ['any', 'Christian', 'Muslim', 'undisclosed'],
      hireFailureReasons: [
        'distance',
        'payment',
        'Service Provider\'s Phone does not work',
        'Service Provider did not answer their phone',
        'Customer did not call service providers',
        'Service Provider did not show up',
        'Service Provider showed up late',
        'Service Provider is not able to do the job',
        'Service Provider doesnot have the required license or CoC',
      ],
      customerBudgetMin: 0,
      customerBudgetMax: 0,
      customerHouseHold: 'undisclosed',
      customerResidence: 'undisclosed',
      customerReligionPreference: 'undisclosed',
      customerResidenceOwnership: 'undisclosed',
      customerHireDemand: 'any',
      hiredProviderIndex: null,
      previousCallSuccess: false,
      lastRequestFailReasonNotes: '',
      lastRequestFailReasons: [],
      lastRequestPaidAmount: 0,
      lastRequestFollowupNote: '',
      customerReqLogUpdated: false,
      smsSendingInProgress: false,
    };
  },
  validations: {
    phoneNumber: {
      required,
      minLen: minLength(13),
      maxLen: maxLength(13),
    },
  },
  computed: {
    queryRadiusMeters() {
      return this.queryRadius * 1000;
    },
    readyForDelivery() {
      return (
        this.selectedProviders.length === 1
      );
    },
    readyForCustomerInfoUpdate() {
      return (
        this.customerFirstName !== ''
        && this.customerLastName !== ''
        && this.customerHouseHold !== null
        && this.customerResidence !== null
        && this.customerResidenceOwnership !== null
        && this.customerReligionPreference !== null);
    },
    readyForQuery() {
      return (
        this.queryService != null
         && this.customerLookedUp === true
         && this.center.lat !== 9.005401
         && this.center.lng !== 38.763611
         && this.followupIsDone
         && this.readyForCustomerInfoUpdate
      );
    },
    lastProviderNameList() {
      return this.lastRequestProviders.map((e) => `${e.firstName} ${e.lastName}`);
    },
    logUpdateRequired() {
      return this.lastRequestProviders.length > 0;
    },
    readyForFollowup() {
      if (this.previousCallSuccess) {
        return (this.hiredProviderIndex !== null
          && this.lastRequestPaidAmount !== 0);
      }
      return this.lastRequestFailReasons.length !== 0;
    },
    followupIsDone() {
      return !(this.logUpdateRequired !== this.customerReqLogUpdated);
    },
  },
  methods: {
    resetQuery() {
      this.phoneNumber = '+251';
      this.customerFirstName = '';
      this.customerLastName = '';
      this.customerLookupInProgress = false;
      this.customerLookedUp = false;
      this.appUserCustomer = false;
      this.nearbyLocation = null;
      this.newCustomer = false;
      this.calls = null;
      this.callCount = 0;
      this.lastRequestService = null;
      this.lastRequestDate = null;
      this.lastRequestProviders = [];
      this.lastHiredProvider = null;
      this.userQueried = false;
      this.supplyResult = false;
      this.queryCategory = null;
      this.queryServiceList = [];
      this.queryService = null;
      this.queryLocation = null;
      this.queryRadius = 1;
      this.queryResult = null;
      this.selectedProviders = [];
      this.queryResultTableItems = [];
      this.lastRequestData = [];
      this.customerBudgetMin = 0;
      this.customerBudgetMax = 0;
      this.customerHouseHold = 'undisclosed';
      this.customerResidence = 'undisclosed';
      this.customerReligionPreference = 'undisclosed';
      this.customerResidenceOwnership = 'undisclosed';
      this.customerHireDemand = null;
      this.hiredProviderIndex = null;
      this.previousCallSuccess = false;
      this.lastRequestFailReasonNotes = '';
      this.lastRequestPaidAmount = 0;
      this.lastRequestFollowupNote = '';
      this.customerReqLogUpdated = false;
      this.noProvidersFoundNearby = false;
      this.lastRequestFailReasons = [];
      this.smsSendingInProgress = false;
    },
    selectHiredProvider(p) {
      this.hiredProviderIndex = this.lastProviderNameList.indexOf(p);
    },
    customerLookUp() {
      this.$store.dispatch('auth/persistSession');
      const db = firebase.firestore();
      this.customerLookupInProgress = true;
      // first check if caller is an app user
      // then check if caller is a new caller
      // for app users get profile data from users collection
      // for non-app users get profile data from cc_requests collection
      db.collection('users')
        .where('phoneNumber', '==', this.phoneNumber)
        .get()
        .then((userSnapshot) => {
          // console.log(userSnapshot.docs[0].data());
          if (userSnapshot.empty) {
            // console.log(userSnapshot.docs[0].data());
            this.appUserCustomer = false;
          } else {
            this.appUserCustomer = true;
            this.customerFirstName = userSnapshot.docs[0].data().firstName;
            this.customerLastName = userSnapshot.docs[0].data().lastName;
          }
          db.collection('cc_requests')
            .where('phoneNumber', '==', this.phoneNumber)
            .get()
            .then((ccRecSnapshot) => {
              if (!ccRecSnapshot.empty) {
                this.newCustomer = false;
                const customerInfo = ccRecSnapshot.docs[0].data();
                this.customerRequestTrackingId = ccRecSnapshot.docs[0].id;
                this.calls = customerInfo.calls;
                this.callCount = this.calls.length;
                this.lastRequestService = this.calls[this.calls.length - 1].requestedService;
                this.lastRequestLocation = this.calls[this.calls.length - 1]
                  .requestLocation.name;
                const customerBudgetMin = this.calls[this.calls.length - 1].requestBudgetMin;
                const customerBudgetMax = this.calls[this.calls.length - 1].requestBudgetMax;
                this.lastRequestDate = this.dateFormat(
                  this.calls[this.calls.length - 1].timeStamp,
                );
                this.lastRequestProviders = this.calls[
                  this.calls.length - 1
                ].serviceProvidersSupplied;
                this.customerFirstName = customerInfo.firstName;
                this.customerLastName = customerInfo.lastName;
                this.customerHouseHold = customerInfo.houseHoldSize;
                this.customerResidence = customerInfo.residenceType;
                this.customerResidenceOwnership = customerInfo.homeOwner;
                this.customerReligionPreference = customerInfo.religionPreference;
                this.lastRequestData = [
                  {
                    lastRequestedService: this.lastRequestService,
                    lastRequestLocation: this.lastRequestLocation,
                    lastRequestDate: this.lastRequestDate,
                    totalNumberOfCalls: this.callCount,
                    appUser: this.appUserCustomer,
                    customerBudgetMin,
                    customerBudgetMax,
                  },
                ];
              } else {
                this.newCustomer = true;
              }
              this.customerLookedUp = true;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    dateFormat(date) {
      const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      // eslint-disable-next-line prefer-template
      return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      // !this.$v.phone.numeric && errors.push('Phone number can have only numerics')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.phoneNumber.required && errors.push('Phone number is required');
      if (!this.$v.phoneNumber.minLen || !this.$v.phoneNumber.maxLen) {
        errors.push('Phone number should be 13 digits including the + sign');
      }
      return errors;
    },
    getServices() {
      const db = firebase.firestore();
      db.collection('services')
        .doc('gooday_classified_services')
        .get()
        .then((servicesSnapshot) => {
          this.services = servicesSnapshot.data().services;
        })
        .catch((error) => console.log(error));
    },
    selectCategory(category) {
      this.queryCategory = category;
      this.queryServiceList = this.services.find(
        (c) => c.category === category,
      ).services;
      this.queryService = null;
    },
    selectService(service) {
      this.queryService = service;
    },
    getAddressData(addressData) {
      // console.log(addressData);
      this.center = { lat: addressData.latitude, lng: addressData.longitude };
      this.queryLocation = addressData;
      this.nearbyLocation = addressData.formatted_address;
    },
    searchProviders() {
      const db = firebase.firestore();
      const queryBounds = geofire.geohashQueryBounds(
        [this.center.lat, this.center.lng],
        this.queryRadiusMeters,
      );
      const promises = [];
      queryBounds.forEach((b) => {
        const q = db
          .collection('goodays')
          .orderBy('geoLoc.geohash')
          .startAt(b[0])
          .endAt(b[1]);
        promises.push(q.get());
      });
      Promise.all(promises)
        .then((snapshots) => {
          const matchingDocs = [];
          // eslint-disable-next-line no-unused-vars
          snapshots.forEach((snap) => {
            snap.forEach((doc) => {
              // console.log(doc.data().geoLoc.geopoint);
              const lat = doc.data().geoLoc.geopoint.latitude;
              const lng = doc.data().geoLoc.geopoint.longitude;
              const distanceInKm = geofire.distanceBetween(
                [lat, lng],
                [this.center.lat, this.center.lng],
              );
              const distanceInM = distanceInKm * 1000;
              if (
                distanceInM <= this.queryRadiusMeters
                && doc.data().services != null
              ) {
                if (doc.data().services.includes(this.queryService)) {
                  matchingDocs.push({
                    provider: doc.data(),
                    distance: distanceInKm,
                  });
                }
              }
            });
          });
          this.queryResult = matchingDocs;
          if (this.queryResult.length === 0) {
            this.noProvidersFoundNearby = true;
          } else {
            this.noProvidersFoundNearby = false;
          }
          // console.log(this.queryResult);
          return matchingDocs;
        })
        .then((matchingDocs) => {
          const providerTabelList = [];
          matchingDocs.forEach((data) => {
            providerTabelList.push({
              firstName: data.provider.firstName,
              lastName: data.provider.lastName,
              phoneNumber: data.provider.phoneNumber,
              services: data.provider.services,
              distance: data.distance.toFixed(2),
              ad: data.provider.summary,
              pos: {
                lat: data.provider.geoLoc.geopoint.latitude,
                lng: data.provider.geoLoc.geopoint.longitude,
              },
              verified: data.provider.approvedUser,
              prefered: data.provider.preferedServiceProvider,
            });
          });
          this.queryResultTableItems = providerTabelList;
          this.supplyResult = true;
        })
        .catch((error) => console.log(error));
    },
    providerVerifiedIcon(b) {
      // console.log(`provider verified : ${b}`);
      return b
        ? { icon: 'mdi-shield-check', color: 'lime' }
        : { icon: 'mdi-close-octagon', color: 'red' };
    },
    providerPreferedIcon(b) {
      // console.log(`provider verified : ${b}`);
      return b
        ? { icon: 'mdi-star-shooting', color: 'lime' }
        : { icon: 'mdi-close-octagon', color: 'red' };
    },
    updateCustomerLog() {
      const db = firebase.firestore();
      const timeNow = new Date();
      if (this.previousCallSuccess) {
        const followupDataHired = {
          cc_agent: firebase.auth().currentUser.uid,
          employer: this.phoneNumber,
          failReasonNotes: this.lastRequestFailReasonNotes,
          failReasons: this.lastRequestFailReasons,
          followupDate: firestore.Timestamp.fromDate(timeNow),
          hired: this.previousCallSuccess,
          notes: this.lastRequestFollowupNote,
          paymentAmount: parseFloat(this.lastRequestPaidAmount),
          requestDate: this.calls[this.calls.length - 1].timeStamp,
          callId: this.calls[this.calls.length - 1].callId,
          serviceProvider: this.lastRequestProviders[this.hiredProviderIndex].phoneNumber,
        };
        // console.log(followupDataHired);
        db.collection('cc_followups').add(followupDataHired).then(() => {
          this.customerReqLogUpdated = true;
        })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const followupDataFailed = {
          cc_agent: firebase.auth().currentUser.uid,
          employer: this.phoneNumber,
          failReasonNotes: this.lastRequestFailReasonNotes,
          failReasons: this.lastRequestFailReasons,
          followupDate: firestore.Timestamp.fromDate(timeNow),
          hired: this.previousCallSuccess,
          notes: this.lastRequestFollowupNote,
          paymentAmount: parseFloat(this.lastRequestPaidAmount),
          requestDate: this.calls[this.calls.length - 1].timeStamp,
          callId: this.calls[this.calls.length - 1].callId,
        };
        // console.log(followupDataFailed);
        db.collection('cc_followups').add(followupDataFailed).then(() => {
          this.customerReqLogUpdated = true;
        })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // connections() {
    //   const db = firebase.firestore();
    //   db.collectionGroup('calls')
    //     // .limit(4)
    //     .get()
    //     .then((snapshots) => {
    //       // const now = Date.now();
    //       // const aprilStart = now - (33 * 24 * 60 * 60 * 1000);
    //       // eslint-disable-next-line no-unused-vars
    //       let aprilCount = 0;
    //       snapshots.forEach((s) => {
    //         if (s.data().timeStamp.seconds * 1000 >= 1617249600000) {
    //           aprilCount += 1;
    //           console.log(s.data().timeStamp.seconds);
    //         }
    //       });
    //       console.log(aprilCount);
    //     })
    //     .catch((errors) => console.log(errors));
    // },
    fullfillRequest() {
      const db = firebase.firestore();
      const timeNow = new Date();
      const callId = Math.floor(Date.now() / 1000 / 60);
      this.smsSendingInProgress = true;
      this.selectedProviders.forEach((e) => { if (e.prefered !== true) { e.prefered = false; } });
      if (this.newCustomer) {
        const customerRequestData = {
          firstName: this.customerFirstName,
          lastName: this.customerLastName,
          phoneNumber: this.phoneNumber,
          appUser: this.appUserCustomer,
          houseHoldSize: this.customerHouseHold,
          residenceType: this.customerResidence,
          homeOwner: this.customerResidenceOwnership,
          nearbyLocation: this.nearbyLocation,
          religionPreference: this.customerReligionPreference,
          calls: [
            {
              callId,
              requestBudgetMin: this.customerBudgetMin,
              requestBudgetMax: this.customerBudgetMax,
              requestHiringType: this.customerHireDemand,
              requestedService: this.queryService,
              serviceProvidersSupplied: this.selectedProviders,
              timeStamp: firestore.Timestamp.fromDate(timeNow),
              callCenterAgent: firebase.auth().currentUser.uid,
              requestLocation: {
                administrativeAreaLevel1: this.queryLocation.administrative_area_level_1,
                country: this.queryLocation.country,
                countryCode: this.queryLocation.country_code,
                formattedAddress: this.queryLocation.formatted_address,
                latitude: this.queryLocation.latitude,
                locality: this.queryLocation.locality,
                longitude: this.queryLocation.longitude,
                name: this.queryLocation.name,
                placeId: this.queryLocation.place_id,
              },
            },
          ],
        };
        // console.log(customerRequestData);
        db.collection('cc_requests')
          .add(
            customerRequestData,
          )
          .then(() => {
            if (this.queryResult.length > 0) {
              let message = `Gooday ${this.queryService} nearby\n`;
              this.selectedProviders.forEach((provider) => {
                message += `${provider.firstName} - ${provider.phoneNumber}\n`;
              });
              // Automated service integration goes here ---
              const smsPayLoad = {
                employername: this.customerFirstName,
                gigworkername: this.selectedProviders[0].firstName,
                employer: this.phoneNumber,
                gigworker: this.selectedProviders[0].phoneNumber,
                service: this.queryService,
              };
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              };
              axios.post('https://goodayon-47465.ew.r.appspot.com/addis', smsPayLoad, config)
                .then(() => {
                  // console.log(result.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              this.smsSendingInProgress = false;
              // eslint-disable-next-line no-alert
              alert(message);
            }
            this.resetQuery();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.calls.push(
          {
            callId,
            requestBudgetMin: this.customerBudgetMin,
            requestBudgetMax: this.customerBudgetMax,
            requestHiringType: this.customerHireDemand,
            requestedService: this.queryService,
            serviceProvidersSupplied: this.selectedProviders,
            timeStamp: firestore.Timestamp.fromDate(timeNow),
            callCenterAgent: firebase.auth().currentUser.uid,
            requestLocation: {
              administrativeAreaLevel1: this.queryLocation.administrative_area_level_1,
              country: this.queryLocation.country,
              countryCode: this.queryLocation.country_code,
              formattedAddress: this.queryLocation.formatted_address,
              latitude: this.queryLocation.latitude,
              locality: this.queryLocation.locality,
              longitude: this.queryLocation.longitude,
              name: this.queryLocation.name,
              placeId: this.queryLocation.place_id,
            },
          },
        );
        db.collection('cc_requests')
          .doc(this.customerRequestTrackingId)
          .set(
            {
              firstName: this.customerFirstName,
              lastName: this.customerLastName,
              phoneNumber: this.phoneNumber,
              appUser: this.appUserCustomer,
              houseHoldSize: this.customerHouseHold,
              residenceType: this.customerResidence,
              homeOwner: this.customerResidenceOwnership,
              nearbyLocation: this.nearbyLocation,
              religionPreference: this.customerReligionPreference,
              calls: this.calls,
            },
            {
              merge: true,
            },
          )
          .then(() => {
            if (this.queryResult.length > 0) {
              let message = `Gooday ${this.queryService} nearby\n`;
              this.selectedProviders.forEach((provider) => {
                message += `${provider.firstName} - ${provider.phoneNumber}\n`;
              });
              // Automated service integration goes here ---
              const smsPayLoad = {
                employername: this.customerFirstName,
                gigworkername: this.selectedProviders[0].firstName,
                employer: this.phoneNumber,
                gigworker: this.selectedProviders[0].phoneNumber,
                service: this.queryService,
              };
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
              };
              axios.post('https://goodayon-47465.ew.r.appspot.com/addis', smsPayLoad, config)
                .then(() => {
                  // console.log(result.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              this.smsSendingInProgress = false;
              // eslint-disable-next-line no-alert
              alert(message);
            }
            this.resetQuery();
          })
          .catch((error) => console.log(error));
      }
    },
  },
  created() {
    this.$store.dispatch('auth/persistSession');
    this.getServices();
  },
};
</script>

<style>
.pac-container,
.pac-item,
.pac-matched,
.pac-item-query {
  width: 240px !important;
  color: #d11313;
}
</style>
