<!-- eslint-disable max-len -->
<template>
  <v-main>
    <v-row>
      <v-card width="700px" height="300px" class="mt-2 mx-auto" color="grey darken-3" elevation="10">
        <v-card-title class="pb-0">
          <h3 style="color: white">
            Payment Account Information (የክፍያ መረጃ መላኪያ)
          </h3>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-form>
            <v-text-field label="CRM Ticket Number" prepend-icon="mdi-cellphone"
              v-model.trim="paymentNotificationTrackingId" dark required />
          </v-form>
          <v-spacer></v-spacer>
          <v-row no-gutters align="center">
            <h4 style="color: white">SMS Message Receiver (ቴክስት ተቀባይ)</h4>
            <v-select :items="recipients" v-model="paymentNotificationRecipient" density="compact" class="ml-10"
              label="SMS Recipient" dark></v-select>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-progress-linear v-if="paymentNotificationInProgress" indeterminate height="20" striped
            color="#e21e81"></v-progress-linear>
          <v-btn v-else :disabled="!readyForPaymentNotification" @click="sendPaymentInformation">
            <v-icon medium color="#E21E81">mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card width="700px" height="600px" class="mt-2 mx-auto" color="grey darken-3" elevation="10">
        <v-card-title class="pb-0">
          <h3 style="color: white">
            Open Case Notification (በሂደት ላይ ላለ ቲኬት SMS መላኪያ)
          </h3>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-form>
            <v-text-field label="CRM Ticket Number" prepend-icon="mdi-file" v-model.trim="openCaseNotificationTrackingId"
              dark required />
          </v-form>
          <v-spacer></v-spacer>
          <v-row no-gutters align="center">
            <v-checkbox label="Custom Contact" v-model="customContactSelected" dark></v-checkbox>
          </v-row>
          <v-spacer></v-spacer>
          <v-form v-if="customContactSelected">
            <v-text-field label="Phone Number" prepend-icon="mdi-cellphone"
              v-model.trim="openCaseNotificationCustomPhoneNumber" dark required />
          </v-form>
          <v-row v-else no-gutters align="center">
            <h4 style="color: white">SMS Message Receiver (ቴክስት ተቀባይ)</h4>
            <v-select :items="recipients" v-model="openCaseNotificationRecipient" density="compact" class="ml-10"
              label="SMS Recipient" dark></v-select>
          </v-row>
          <v-textarea v-model="openCaseNotificationMessage" color="#E21E81" label="SMS Message" :counter="160" filled
            required multi-line dark></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-progress-linear v-if="openCaseNotificationInProgress" indeterminate height="20" striped
            color="#e21e81"></v-progress-linear>
          <v-btn v-else :disabled="!readyForOpenCaseNotification" @click="sendOpenCaseNotification">
            <v-icon medium color="#E21E81">mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-spacer></v-spacer>
    <!-- <v-row>
      <v-card
        width="700px"
        height="400px"
        class="mt-10 mx-auto"
        color="grey darken-3"
        elevation="10"
      >
        <v-card-title class="pb-0">
          <h3 style="color: white">Custom Notification (የተለየ መልእክት)</h3>
        </v-card-title>
      </v-card>
    </v-row> -->
  </v-main>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      paymentNotificationTrackingId: null,
      paymentNotificationRecipient: null,
      openCaseNotificationTrackingId: null,
      openCaseNotificationRecipient: null,
      openCaseNotificationCustomPhoneNumber: '+251',
      openCaseNotificationMessage: '',
      caseLookUpInProgress: false,
      paymentNotificationInProgress: false,
      openCaseNotificationInProgress: false,
      invalidTrackingId: false,
      customContactSelected: false,
      trackingCaseNotActive: false,
      recipients: ['Employer (ቀጣሪ)', 'Service Provider (ባለሞያ)'],
    };
  },
  methods: {
    async caseLookUp(crmTrackingId) {
      this.caseLookUpInProgress = true;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      const crmPayLoad = {
        trackingid: crmTrackingId,
        mode: 'lookup',
      };
      return axios.post(process.env.VUE_APP_GCRM_URL, crmPayLoad, config)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === '<nil>') {
            this.invalidTrackingId = true;
            this.caseLookUpInProgress = false;
            return null;
          }
          this.caseLookUpInProgress = false;
          return resp.data;
        })
        .catch((error) => {
          console.log(error);
          this.caseLookUpInProgress = false;
          return null;
        });
    },
    sendPaymentInformation() {
      this.paymentNotificationInProgress = true;
      if (this.invalidTrackingId) {
        // eslint-disable-next-line no-alert
        alert('Invalid Ticket');
        this.resetPaymentNotificationFields();
        return;
      }
      this.caseLookUp(this.paymentNotificationTrackingId)
        .then((jira) => {
          console.log(jira);
          if (jira === null) {
            // eslint-disable-next-line no-alert
            alert('Invalid Ticket');
            this.resetPaymentNotificationFields();
            return;
          }
          const jiraStatus = jira.fields.status.statusCategory.key;
          if (jiraStatus === 'done') {
            this.trackingCaseNotActive = true;
            // eslint-disable-next-line no-alert
            alert(`${this.paymentNotificationTrackingId} is not an active JIRA ticket. SMS notification will not be sent.`);
            this.resetPaymentNotificationFields();
          } else {
            let recipientPhoneNumber = '';
            if (this.paymentNotificationRecipient === 'Employer (ቀጣሪ)') {
              recipientPhoneNumber = jira.fields.customfield_10036;
            } else {
              recipientPhoneNumber = jira.fields.customfield_10040;
            }
            // send SMS
            const message = 'G-Online Digital Advertising PLC\nየክፍያ መንገዶች\nTelebirr: +251949231010\nCBE: 1000440970118\nAwash: 01304412900200\nBoA: 124327539\n\nGoodayOn Guarantee (ውስን ዋስትና): https://cutt.ly/pei4fpoY';
            const smsPayLoad = {
              simpleSMSReceiver: recipientPhoneNumber,
              service: this.queryService,
              message,
              crmtrackingid: this.paymentNotificationTrackingId,
              mode: 'simplesms',
            };
            // comment on ticket
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            };
            axios
              .post(process.env.VUE_APP_NOTIFY_URL, smsPayLoad, config)
              .then(() => {
                // eslint-disable-next-line no-alert
                alert('SMS is sent!');
                const crmCommentPayLoad = {
                  mode: 'comment',
                  trackingid: this.paymentNotificationTrackingId,
                  bulksmsmessage: `📣 *Payment information sent to ${this.paymentNotificationRecipient}* 📣\n\n"${message}"`,
                };
                console.log(crmCommentPayLoad);
                axios.post(process.env.VUE_APP_GCRM_URL, crmCommentPayLoad, config)
                  .then(() => {
                    this.resetPaymentNotificationFields();
                  })
                  .catch((error) => {
                    console.log(error);
                    this.resetPaymentNotificationFields();
                  });
              })
              .catch((error) => {
                console.log(error);
                // eslint-disable-next-line no-alert
                alert('Failed to send SMS message');
                this.resetPaymentNotificationFields();
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.resetPaymentNotificationFields();
        });
    },
    sendOpenCaseNotification() {
      this.openCaseNotificationInProgress = true;
      if (this.invalidTrackingId) {
        // eslint-disable-next-line no-alert
        alert('Invalid Ticket');
        this.resetOpenCaseNotificationFields();
        return;
      }
      this.caseLookUp(this.openCaseNotificationTrackingId)
        .then((jira) => {
          if (jira === null) {
            // eslint-disable-next-line no-alert
            alert('Invalid Ticket');
            this.resetOpenCaseNotificationFields();
            return;
          }
          const jiraStatus = jira.fields.status.statusCategory.key;
          if (jiraStatus === 'done') {
            this.trackingCaseNotActive = true;
            // eslint-disable-next-line no-alert
            alert(`${this.openCaseNotificationTrackingId} is not an active JIRA ticket. SMS notification will not be sent.`);
            this.resetOpenCaseNotificationFields();
          } else {
            let recipientPhoneNumber = '';
            if (this.customContactSelected) {
              recipientPhoneNumber = this.openCaseNotificationCustomPhoneNumber;
            } else if (this.openCaseNotificationRecipient === 'Employer (ቀጣሪ)') {
              recipientPhoneNumber = jira.fields.customfield_10036;
            } else {
              recipientPhoneNumber = jira.fields.customfield_10040;
            }
            // send SMS
            const smsPayLoad = {
              simpleSMSReceiver: recipientPhoneNumber,
              service: this.queryService,
              message: this.openCaseNotificationMessage,
              crmtrackingid: this.openCaseNotificationTrackingId,
              mode: 'simplesms',
            };
            // comment on ticket
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            };
            axios
              .post(process.env.VUE_APP_NOTIFY_URL, smsPayLoad, config)
              .then(() => {
                // eslint-disable-next-line no-alert
                alert('SMS is sent!');
                const crmCommentPayLoad = {
                  mode: 'comment',
                  trackingid: this.openCaseNotificationTrackingId,
                  bulksmsmessage: `📣 *SMS message sent to ${this.customContactSelected ? this.openCaseNotificationCustomPhoneNumber : this.openCaseNotificationRecipient}* 📣\n\n"${this.openCaseNotificationMessage}"`,
                };
                console.log(crmCommentPayLoad);
                axios.post(process.env.VUE_APP_GCRM_URL, crmCommentPayLoad, config)
                  .then(() => {
                    this.resetOpenCaseNotificationFields();
                  })
                  .catch((error) => {
                    console.log(error);
                    this.resetOpenCaseNotificationFields();
                  });
              })
              .catch((error) => {
                console.log(error);
                // eslint-disable-next-line no-alert
                alert('Failed to send SMS message');
                this.resetOpenCaseNotificationFields();
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.resetOpenCaseNotificationFields();
        });
    },
    resetPaymentNotificationFields() {
      this.paymentNotificationTrackingId = null;
      this.paymentNotificationRecipient = null;
      this.paymentNotificationInProgress = false;
      this.invalidTrackingId = false;
    },
    resetOpenCaseNotificationFields() {
      this.openCaseNotificationTrackingId = null;
      this.openCaseNotificationRecipient = null;
      this.openCaseNotificationMessage = '';
      this.invalidTrackingId = false;
      this.customContactSelected = false;
      this.openCaseNotificationCustomPhoneNumber = '+251';
      this.openCaseNotificationInProgress = false;
    },
  },
  computed: {
    readyForPaymentNotification() {
      return (
        this.paymentNotificationTrackingId !== null
        && this.paymentNotificationRecipient !== null
      );
    },
    readyForOpenCaseNotification() {
      return (
        this.openCaseNotificationTrackingId !== null
        && ((this.openCaseNotificationRecipient !== null
          && this.customContactSelected === false)
          || (this.customContactSelected === true
            && this.openCaseNotificationCustomPhoneNumber.length === 13
            && this.openCaseNotificationCustomPhoneNumber.startsWith('+251')))
        && this.openCaseNotificationMessage.length > 20
        && this.openCaseNotificationMessage.length < 160
      );
    },
  },
};
</script>
