<template>
<v-main>
  <v-row align="center" justify="center">
      <v-btn icon @click="refresh()" color="green">
      <v-icon>mdi-cached</v-icon>
      </v-btn>
        <div>
          <span style="color: #e21e81"> service providers ({{ providersCount }}) </span>
          <span style="color: lime"> regular users ({{ customersCount }}) </span>
          <span style="color: white"> @ {{ timeStamp }} </span>
        </div>
  </v-row>
  <GmapMap
  :center="center"
  :zoom="13"
  map-type-id="roadmap"
  style="width: 100%; height: 600px"
>
  <GmapMarker
    v-for="(m, index) in markers"
    :key="index"
    :position="m"
    :clickable="true"
    :draggable="false"
    :icon="{ url: require('../assets/provider_icon.png')}"
    @click="center=m"
  />
  <GmapMarker
    v-for="(m,index) in customerMarkers"
    :key="index + 20000000"
    :position="m"
    :clickable="true"
    :draggable="false"
    :icon="{ url: require('../assets/user_icon.png')}"
    @click="center=m"
  />
</GmapMap>
</v-main>
</template>

<script>
import { firebaseAuthMixins } from './mixins/firebaseAuth';

export default {
  data: () => ({
    center: { lat: 9.005401, lng: 38.763611 },
    shape: {
      coords: [10, 10, 10, 15, 15, 15, 15, 10],
      type: 'poly',
    },
  }),
  mixins: [
    firebaseAuthMixins,
  ],
  computed: {
    markers() {
      return this.$store.getters['auth/providers'];
    },
    customerMarkers() {
      return this.$store.getters['auth/customers'];
    },
    providersCount() {
      return this.$store.getters['auth/providersCount'];
    },
    customersCount() {
      return this.$store.getters['auth/customersCount'];
    },
    timeStamp() {
      const today = new Date();
      return `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}`;
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch('auth/appUsers');
    },
  },
};
</script>
