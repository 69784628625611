<template>
  <v-main>
    <v-card width="1200px" class="mt-5 mx-auto" color="#e21e81" outlined>
      <v-card-title style="color: white" class="center">
        Account Delete Requests
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn elevation="4" @click="pullDeleteRequests">
          <v-icon color="#e21e81"> mdi-briefcase-search </v-icon>
        </v-btn>
      </v-card-actions>
      <v-data-table
        v-if="accountDeleteRequestData != null"
        :headers="headers"
        :items="accountDeleteRequestData"
        sort-by="reportDate"
        class="elevation-1"
      >
      <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Account delete requests</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Deboarding user feedback</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                  <v-row align="center" justify="center" style="color:#E21E81">
                  Confirm Account Delete ?
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                  <v-checkbox
                    v-model="deleteConfirm"
                    label="Delete Account"
                    color="#e21e81"
                  >
                  </v-checkbox>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" style="color:#E21E81">
                  User feedback and/or reason for deleting account
                </v-row>
                <v-row>
                  <v-col
                  >
                    <v-text-field
                      color="#E21E81"
                      v-model="followUpNotes"
                      label="Follow up notes"
                      maxLength="80"
                      counter="80"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#E21E81"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="#E21E81"
                text
                @click="update"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
        <template v-slot:item.actions="{ item }">
      <v-icon
        small
        color="#E21E81"
        class="mr-2"
        @click="deleteFollowUp(item)"
      >
        mdi-delete-clock
      </v-icon>
    </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import firebase, { firestore } from 'firebase/app';

export default {
  data() {
    return {
      dialog: false,
      deleteConfirm: false,
      followUpNotes: null,
      accountDeleteRequestData: null,
      indexOfDeleteRequest: null,
      searchInProgress: false,
      followUpUser: null,
      headers: [
        {
          text: 'Delete Request Date',
          align: 'start',
          sortable: false,
          value: 'requestDate',
        },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Phone Number', value: 'phoneNumber' },
        { text: 'Account Type', value: 'accountType' },
        { text: 'Join Date', value: 'accountCreate' },
        { text: 'Recent Access', value: 'lastAccess' },
        { text: 'Delete Request Date', value: 'requestDate' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    deleteFollowUp(item) {
      this.deleteConfirm = false;
      this.indexOfDeleteRequest = this.accountDeleteRequestData.indexOf(item);
      this.followUpUser = item;
      this.dialog = true;
      //  console.log(item);
    },
    close() {
      this.dialog = false;
      this.deleteConfirm = false;
      this.followUpUser = null;
      this.followUpNotes = null;
      this.followupPhoneNumber = null;
    },
    update() {
      const db = firebase.firestore();
      const timeNow = new Date();
      if (this.deleteConfirm === true) {
        db.collection('users')
          .doc(this.followUpUser.userId)
          .delete()
          .then(() => {
            if (this.followUpUser.accountType === 'Service Provider') {
              db.collection('goodays')
                .doc(this.followUpUser.userId)
                .delete()
                .then()
                .catch((error) => console.log(error));
            }
            //   update accountDeleteRequest collection
            db.collection('accountDeleteRequest')
              .doc(this.followUpUser.userId)
              .set({
                processedBy: firebase.auth().currentUser.uid,
                processed: true,
                accountDeleted: this.deleteConfirm,
                userFeedback: this.followUpNotes,
                processedAt: firestore.Timestamp.fromDate(timeNow),
              }, { merge: true })
              .then(() => {
                if (this.indexOfDeleteRequest > -1) {
                  this.accountDeleteRequestData.splice(this.indexOfDeleteRequest, 1);
                }
                this.close();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      } else {
        //   update accountDeleteRequest collection
        db.collection('accountDeleteRequest')
          .doc(this.followUpUser.userId)
          .set({
            processedBy: firebase.auth().currentUser.uid,
            processed: true,
            accountDeleted: this.deleteConfirm,
            userFeedback: this.followUpNotes,
            processedAt: firestore.Timestamp.fromDate(timeNow),
          }, { merge: true })
          .then(() => {
            if (this.indexOfDeleteRequest > -1) {
              this.accountDeleteRequestData.splice(this.indexOfDeleteRequest, 1);
            }
            this.close();
          })
          .catch((error) => console.log(error));
      }
    },
    dateFormat(date) {
      const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      // eslint-disable-next-line prefer-template
      return (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + '-' + y;
    },
    pullDeleteRequests() {
      const requestList = [];
      const db = firebase.firestore();
      db.collection('accountDeleteRequest')
        .get()
        .then((requestSnapShot) => {
          const requestIdList = [];
          // console.log(requestSnapShot);
          requestSnapShot.docs.forEach((requestRef) => {
            if (requestRef.data().processed == null) {
              requestIdList.push(requestRef);
            }
            // console.log(requestRef.id);
          });
          requestIdList.forEach((request) => {
            db.collection('users')
              .doc(request.id)
              .get()
              .then((userSnapShot) => {
                // console.log(userSnapShot.data());
                requestList.push({
                  userId: request.id,
                  firstName: userSnapShot.data().firstName,
                  lastName: userSnapShot.data().lastName,
                  phoneNumber: userSnapShot.data().phoneNumber,
                  accountType: userSnapShot.data().serviceProvider === true ? 'Service Provider' : 'Regular User',
                  accountCreate: this.dateFormat(userSnapShot.data().createdAt),
                  lastAccess: this.dateFormat(userSnapShot.data().lastUpdate),
                  requestDate: this.dateFormat(request.data().timeStamp),
                });
              })
              .catch((error) => console.log(error));
          });
          this.accountDeleteRequestData = requestList;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.$store.dispatch('auth/persistSession');
  },
};
</script>
