<template>
  <v-main>
    <v-row>
      <VueGooglePlaces
        id="map"
        :options="{ fields: ['geometry'] }"
        types="establishment"
        country="et"
        label=""
        @placechanged="getAddressData"
        style="width: 100%"
      >
        <v-text-field
          dense
          dark
          hide-details=""
          class="ma-0 pb-4 pr-8 pl-8"
          label="Enter location name"
          outlined
        />
      </VueGooglePlaces>
    </v-row>
    <v-row>
      <GmapMap
        :center="center"
        :zoom="13"
        map-type-id="roadmap"
        style="width: 100%; height: 300px"
      >
        <GmapMarker
          :position="position"
          @click="center=m.position"
        />
      </GmapMap>
    </v-row>
  </v-main>
  </template>
<script>

import Geohash from 'latlon-geohash';

export default {
  data: () => ({
    position: null,
    center: { lat: 9.005401, lng: 38.763611 },
  }),
  methods: {
    // pinDrop(event) {
    //   // console.log(event.latLng.lat());
    //   // console.log(event.latLng.lng());
    //   this.position = {
    //     lat: event.latLng.lat(),
    //     lng: event.latLng.lng(),
    //   };
    // },
    getAddressData(addressData) {
      // console.log(addressData);
      this.position = { lat: addressData.latitude, lng: addressData.longitude };
    },
    locationData() {
      const geohash = Geohash.encode(this.position.lat, this.position.lng, 9);
      this.$emit('locationData', {
        lat: this.position.lat,
        lng: this.position.lng,
        geohash,
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.center = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
      });
    },
  },
  updated() {
    if (this.position) this.locationData();
    this.center = this.position;
  },
  // beforeMount() {
  //   this.geolocate();
  // },
};
</script>
