<template>
  <v-main>
    <v-layout text-center wrap>
      <!-- <v-flex xs12>
        <v-img :src="require('../assets/Banner-Gooday-Cloud-TM-logo.png')"
        class="my-3" contain height="180"></v-img>
      </v-flex> -->

      <v-flex mb-4>
        <h1
        class="font-weight-bold mb-3 content">
          GoodayOn(ጉዳይኦን) App</h1>
      </v-flex>

      <v-flex mb-5 xs12>
        <h2  class="font-weight-bold mb-3 content amharic ">
          ጉዳይኦን  ምንድነው ?</h2>
        <v-layout justify-center>
          <p  class="detail amharic">ጉዳይኦን(GoodayOn) ለአጠቃቀም ቀላል ተደርጎ የተሰራ አገልግሎት ሰጪዎችን
             (ሰራተኞችን) እና አገልግሎት ተጠቃሚዎችን (ደንበኞችን) በቀላሉ የሚያገናኝ <a href="https://gooday.io/#/goodayon" class="lime">ትግበራ(App)</a> ነው ።</p>
        </v-layout>
      </v-flex>

      <v-flex xs12 mb-5 mx-5>
        <h2  class="font-weight-bold mb-3 content amharic">
          የጉዳይኦን ጥቅም ምንድነው ?</h2>
          <ul>
            <p class="detail amharic">በቅርብ ርቀት ላይ የሚገኙ አገልግሎት ሰጪ (ባለሞያ) ሰራተኞች እና አገልግሎት
               ፈላጊ (ተጠቃሚዎችን) ያለምንም ክፍያ (ኮሚሽን) ያገናኛል።</p>
            <p class="detail amharic">አገልግሎት ሰጪዎች ሙያቸውን የሚያስተዋውቁበት እና ገበያ የሚያገኙበት መድረክ ይፈጥራል።</p>
            <p class="detail amharic">የተመሰከረላቸውና ምስጉን የሆኑ ባለሞያዎች  በትግበራው ላይ ቅድሚያ በማግኘት የገበያ እድላቸው
               ሰፊ እንዲሆን ያደርጋል።</p>
            <p class="detail amharic">ትግበራው የአገልግሎት ሰጪዎችን ትክክለኛ ማንነት የሚያጣራበት ስርዓት ስላለው አገልግሎት
               ፈላጊዎች የትክክለኛ ማንነት ማረጋገጫ
               አርማ በማየት የሚፈልጉትን ባለሞያ መምረጥ ይችላሉ።</p>
            <p class="detail amharic">ምስጉን ሰራተኞችን ለማበረታታት ተጠቃሚዎች ምስጋናቸውንና
               ምስክርነታቸውን መስጠት የሚችሉበት ነው።</p>
            <p class="detail amharic">የሃሰት (Fake) አካውንት ወይም ህገ-ወጥ እና የማጭበርበር ድርጊቶችን ለመቆጣጠር
               ሪፖርት ማድረጊያ መንገድ አዘጋጅቷል።</p>
            <p class="detail amharic">ስለ አፕሊኬሽኑ አስፈላጊውን ድጋፍና ምክር በቀላሉ ማግኘት ይቻላል።</p>
          </ul>
        <v-layout justify-center></v-layout>
      </v-flex>

      <v-flex xs12 mb-5>
        <h2
          class="font-weight-bold mb-3 content amharic"
        >ጉዳይኦን እንዴት ነው የሚሰራው ?</h2>
        <v-layout justify-center>
          <v-img src="../assets/Flow-Outline.png" max-height="600" contain></v-img>
        </v-layout>
      </v-flex>

       <v-flex xs12 mb-5>
        <h2
          class="font-weight-bold mb-3 content amharic"
        >የጉዳይኦን ትግበራ የአጠቃቀም ምክሮችና ጥንቃቄዎች</h2>
        <v-layout justify-center>
          <ul>
            <p class="detail amharic">ምን ጊዜም ስራ ከመጀመርዎ በፊት በዋጋ ተደራድረው ይስማሙ ፤ በዋጋ ሳይስማሙ ስራ አይጀምሩ።</p>
            <p class="detail amharic">ለሰራ ወደ ደንበኛዎት ቤት ሲሄዱ ለመጀመሪያ ጊዜ ከሆነ ለደህንነትዎ ሲሉ ሌላ ሰው ይዘው
               በመሄድ ወይም በሌላ ዘዴ የሄዱበትን ቤት ከእርስዎ ተጨማሪ ሌላ ሰው እንዲያውቅ ያድርጉ።</p>
            <p class="detail amharic">ለስራ ሰው ቤት ሲሄዱ የሞባይል ስልክዎ በቂ ቻርጅ እንዳለው ያረጋግጡ፣ ምናልባት
               ደህንነትዎን የሚያሰጋ ችግር ቢፈጠር፤ በስልክ እርዳታ ለመጥራት ይጠቅማል።</p>
            <p class="detail class amharic">ለስራ የሚሄዱበትን ቤት እና አካባቢ በደንብ ያስተውሉ፣ ለደህንነትዎ
               የሚያሰጋ የሚመስሎት ማንኛውም አይነት ነገር ካለ ከአካባቢው በመራቅ ለደህንነትዎ ቅድሚያ ይስጡ።</p>
            <p class="detail amharic">ከሁሉም ነገር በላይ ለደህንነትዎ ቅድሚያ ይስጡ፣ የደህንነትዎ
               ሃላፊነት ሙሉ በሙሉ እርስዎ ላይ ነው።</p>
          </ul>
        </v-layout>
      </v-flex>
      <v-flex xs12 mb-5>
        <h2
          class="font-weight-bold mb-3 content "
        >መልካም ቀን™</h2>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  created() {
    this.$store.dispatch('auth/persistSession');
  },
};
</script>

<style>
.content {
  color: lime;
}
.detail {
  color: white;
}
</style>
