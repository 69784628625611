<template>
  <v-main>
    <v-card width="400px" class="mt-5 mx-auto" color="grey darken-3">
      <v-card-title class="pb-0">
        <h3 style="color: white">Gooday User Lookup</h3>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-form>
          <v-text-field
            label="Service provider phone number"
            prepend-icon="mdi-cellphone"
            v-model="phone"
            dark
            :error-messages="phoneErrors"
            required
            @input="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="mr-10" :disabled="$v.phone.$invalid" dark @click="lookUp">
          <v-icon>mdi-account-search</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-container v-if="inspectMode && !userNotFound">
      <v-row  no-gutters align-content="start">
        <v-col class="mx-2">
          <v-card v-if="goodayUserData != null" width="320px"
           class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              User Information
            </v-card-title>
            <div @click="show" >
              <div class="images"  v-viewer="{movable: false}">
                <div class="fill" height="200" width="300" >
                  <img  width="300"  :src=goodayUserData.profilePic />
                </div>
              </div>
            </div>
            <v-card-title style="color:white">
              {{ goodayUserData.firstName }} {{ goodayUserData.lastName }}
            </v-card-title>
            <v-card-text width="300">
              <v-row>
                <div style="color:white">
                  {{ goodayUserData.sex }} {{ goodayUserData.age }}
                </div>
              </v-row>
              <v-row>
                <div style="color:white">
                  {{ accountType }}
                </div>
              </v-row>
              <v-row v-if="goodayUserData != null">
                <div style="color:white">
                  <span v-if="goodayUserData.verifiedUser">
                    Gooday Verification
                    <v-icon small color="green">
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </span>
                  <span v-else>
                    Gooday Verification
                    <v-icon small color="amber">
                      mdi-close-circle
                    </v-icon>
                  </span>
                </div>
              </v-row>
              <v-row v-if="goodayUserData != null">
                <div style="color:white">
                  <span v-if="goodayUserData.coc">
                    Certificate of Competency (CoC)
                    <v-icon small color="green">
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </span>
                  <span v-else>
                    Certificate of Competency (CoC)
                    <v-icon small color="amber">mdi-close-circle</v-icon>
                  </span>
                </div>
              </v-row>
              <v-row v-if="goodayUserData != null">
                <div style="color:white">
                  <span v-if="goodayUserData.qualityProvider">
                    Gooday Quality Provider
                    <v-icon small color="green">
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </span>
                  <span v-else>
                    Gooday Quality Provider
                    <v-icon small color="amber">
                      mdi-close-circle
                    </v-icon>
                  </span>
                </div>
              </v-row>
              <v-row v-if="goodayUserData != null">
                <div style="color:white">
                  <span v-if="goodayUserData.premiumProvider">
                    Gooday Premium Provider
                    <v-icon small color="green">
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </span>
                  <span v-else>
                    Gooday Premium Provider
                    <v-icon small color="amber">
                      mdi-close-circle
                    </v-icon>
                  </span>
                </div>
              </v-row>
              <v-row v-if="goodayUserData != null">
                <div style="color:white">
                  <span v-if="goodayUserData.trainingPartners != null">
                    Gooday Training Partner
                    <v-chip
                      v-for="p in goodayUserData.trainingPartners"
                      :key="p" color="white">
                      {{ p }}
                    </v-chip>
                  </span>
                  <span v-else>
                    Gooday Training Partner
                    <v-icon small color="amber">
                      mdi-close-circle
                    </v-icon>
                  </span>
                </div>
              </v-row>
              <v-row v-if="goodayUserData != null">
                <div style="color:white">
                  <span v-if="goodayUserData.preferedServiceProvider">
                    Prefered Service Provider
                    <v-icon small color="green">
                      mdi-star-shooting
                    </v-icon>
                  </span>
                  <span v-else>
                    Prefered Service Provider
                    <v-icon small color="amber">
                      mdi-close-circle
                    </v-icon>
                  </span>
                </div>
              </v-row>
              <v-row>
                <div style="color:white">
                  Created on {{  createDate }}
                </div>
              </v-row>
              <v-row>
                <div style="color:white">
                  Last active on {{  updateDate }}
                </div>
              </v-row>
              <v-row>
                <div style="color:white">
                  <h4> Last Seen Location </h4>
                </div>
              </v-row>
              <v-row >
                <GmapMap
                :center="recentLocation"
                :zoom="11"
                map-type-id="roadmap"
                style="width: 100%; height: 200px"
                >
                  <GmapMarker
                    :position="recentLocation"
                    @click="center=recentLocation"
                  />
                </GmapMap>
              </v-row>
              <v-row v-if="goodayUserData != null ">
                <div style="color:white">
                  <span v-if="goodayUserData.onboardingAgentBadgeNumber">
                    Onboarded by {{ onboardingAgent }}
                  </span>
                </div>
              </v-row>
            </v-card-text>
            <v-card-actions>
                  <v-btn dark>
                    <v-icon color="#FFFFFF" @click="editProfile"  size="20">mdi-pencil</v-icon>
                    Edit
                  </v-btn>
            </v-card-actions>
            <v-card-actions>
                  <v-btn dark v-if="goodayUserData.coc" @click="removeCocPriority">
                    <v-icon color="#FFFFFF"  size="20">mdi-close-octagon</v-icon>
                    COC
                  </v-btn>
                  <v-btn dark v-if="goodayUserData.qualityProvider" @click="removeQualityPriority">
                    <v-icon color="#FFFFFF"  size="20">mdi-close-octagon</v-icon>
                    Quality
                  </v-btn>
                  <v-btn dark v-if="goodayUserData.preferedServiceProvider"
                   @click="removePreferedFlag">
                    <v-icon color="#FFFFFF"  size="20">mdi-close-octagon</v-icon>
                    Prefered
                  </v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn dark @click="editLocation">
                <v-icon color="#FFFFFF" size="20">mdi-map-marker-radius</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions
            v-if="goodayUserData.serviceProvider
             && !goodayUserData.premiumProvider
             && goodayUserData.verifiedUser
             && !goodayAdNotFound
             && serviceProviderData !== null">
              <v-btn dark @click="premiumStatusUpdate">
                <v-icon color="#FFFFFF" size="20">mdi-cash-clock</v-icon>
              </v-btn>
            </v-card-actions>
            <v-dialog
              v-model="premiumEditDialog"
              max-width="600px">
              <v-card dark>
                <v-card-title>
                  <v-row align="center" justify="center">
                    <span class="headline">Updating Premium Status</span>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters align="center">
                    <v-col>
                      <v-select
                        v-model="selectedPremiumPackageName"
                        :items="paymentPackages"
                        label="Premium Package"
                        hint="Select Premium Package"
                        persistent-hint
                        ></v-select>
                    </v-col>
                    <v-col v-if="selectedPremiumPackageName !== null">
                      <v-chip class="mx-5"  > ETB {{ selectedPackagePaymentAmount  }}</v-chip>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center">
                    <v-col>
                      <v-select
                        v-model="selectedPaymentMethod"
                        :items="paymentMethods"
                        label="Payment Method"
                        hint="Select Payment Method"
                        persistent-hint
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        class="mx-5"
                        v-model="premiumServicePaidAmount"
                        label="Enter paid amount"
                        outlined
                        dense
                        :rules="numberRules"/>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center">
                    <image-cropping
                      dark
                      formLabel="Payment Receipt"
                      v-on:listingPicture="receiptImageData = $event"
                    ></image-cropping>
                    <span v-if="$v.receiptImageData.$invalid"
                      style="color: red; font-size:12px" mb-4>
                      <v-icon small color="amber">mdi-alert</v-icon>
                      Payment receipt is required!
                    </span>
                  </v-row>
                  <v-row>
                    <v-checkbox
                      v-model="premiumPaymentConfirm"
                      label="I have verified this payment is correct and would like to submit."
                    >
                    </v-checkbox>
                  </v-row>
                </v-card-text>
                <v-card-actions v-if="!premiumStatusUpdateInprogress">
                  <v-row>
                    <v-btn dark @click="cancelPremiumPayment" class="mx-5">
                     Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      dark
                      @click="updatePremiumStatus"
                      :disabled="!readyForPremiumStatusUpdate"
                      class="mr-5">
                      Submit
                    </v-btn>
                  </v-row>
                </v-card-actions>
                <v-progress-linear
                  v-else
                  indeterminate
                  rounded
                  height="6"
                  color="#E21E81"
                ></v-progress-linear>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="locationEditDialog"
              max-width="600px">
              <v-card
              dark>
                <v-card-title>
                      <v-row align="center" justify="center">
                      <span class="headline">Updating User Location</span>
                      </v-row>
                    </v-card-title>
                <v-card-text class="my-1 py-2">
                  <!-- <VueGooglePlaces
                    id="map"
                    :options="{ fields: ['geometry'] }"
                    types="establishment"
                    country="et"
                    @placechanged="getNewAddressData"
                    style="width: 100%"
                  > -->
                  <google-map
                    v-on:locationData="newLocation = $event"
                  />
                    <!-- <v-text-field
                      dense
                      dark
                      v-model="nearbyLocation"
                      hide-details=""
                      class="ma-0 pt-1"
                      label=""
                      hint="new user location"/>
                  </VueGooglePlaces> -->
                  <v-row>
                          <v-checkbox
                            v-model="newLocationConfirm"
                            label="I verify this is the location confirmed by the service provider."
                            color="#e21e81"
                          >
                          </v-checkbox>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row v-if="goodayUserData.manualLocationUpdate != null"
                  class="footer-note"
                  style="color: lime; font-size:12px">
                    Location updated by GDO staff
                     {{ goodayUserData.manualLocationUpdate.gdoEditor }} on
                    {{ dateFormat(goodayUserData.manualLocationUpdate.gdoEditTimeStamp) }}
                  </v-row>
                </v-card-text>
                <v-card-actions v-if="!userLocationUpdateInProgress">
                  <v-btn dark
                    @click="updateLocation" :disabled="!readyForLocationUpdate">
                  <v-icon color="#FFFFFF" size="20">mdi-map-marker-check</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  v-else
                  indeterminate
                  rounded
                  height="6"
                  color="#E21E81"
                ></v-progress-linear>
              </v-card>
            </v-dialog>
            <v-dialog
                  v-model="profileEditDialog"
                  max-width="600px">
                  <v-card>
                    <v-card-title>
                      <v-row align="center" justify="center">
                      <span class="headline">Updating User Profile Info</span>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-spacer></v-spacer>
                      <v-container>
                          <v-row align="center" justify="center" style="color:#E21E81" class="my-2">
                            <v-icon color="#E21E81">mdi-alert-octagon</v-icon>
                          You are editing user data, make sure there are
                           no mistakes!
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-row align="center" justify="center" class="my-2">
                          User Info
                        </v-row>
                        <v-divider></v-divider>
                        <v-checkbox
                        v-model="editProfilePicture"
                        label="Update user profile picture"></v-checkbox>
                        <v-row v-if="editProfilePicture" black class="image-section">
                          <image-cropping
                          formLabel="Profile Picture"
                          v-on:listingPicture="profileImageData = $event"
                          ></image-cropping>
                        </v-row>
                        <v-row>
                          <v-col
                          >
                            <v-text-field
                              v-model="firstNameUpdate"
                              color="#E21E81"
                              label="First Name"
                              required
                              :error-messages="firstNameEditErrors"
                              @load="$v.firstNameUpdate.$touch()"
                              @input="$v.firstNameUpdate.$touch()"
                              @blur="$v.firstNameUpdate.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          >
                            <v-text-field
                              v-model="lastNameUpdate"
                              color="#E21E81"
                              label="Last Name"
                              required
                              :error-messages="lastNameEditErrors"
                              @load="$v.lastNameUpdate.$touch()"
                              @input="$v.lastNameUpdate.$touch()"
                              @blur="$v.lastNameUpdate.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="ageUpdate"
                              color="#E21E81"
                              label="Age"
                              required
                              :error-messages="ageUpdateErrors"
                              @load="$v.ageUpdate.$touch()"
                              @input="$v.ageUpdate.$touch()"
                              @blur="$v.ageUpdate.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-select
                              v-model="genderUpdate"
                              color="#E21E81"
                              item-color="#E21E81"
                              :items="genders"
                              label="Gender"
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-4">
                          Mark as prefered service provider
                        </v-row>
                        <v-divider></v-divider>
                        <v-row no-gutters>
                          <v-checkbox
                            v-model="preferedServiceProvider"
                            label="Mark as prefered service provider"
                            color="#e21e81"
                          >
                          </v-checkbox>
                        </v-row>
                        <v-row no-gutters v-if="preferedServiceProvider">
                          <v-text-field
                            label="Reason for marking as prefered service provider"
                            v-model="preferedServiceProviderNote"
                            outlined
                            color="#E21E81"
                            required
                            :error-messages="preferedServiceProviderNoteErrors"
                            @load="$v.preferedServiceProviderNote.$touch()"
                            @input="$v.preferedServiceProviderNote.$touch()"
                            @blur="$v.preferedServiceProviderNote.$touch()"
                          ></v-text-field>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-4">
                          Certification of accuracy and due diligence
                        </v-row>
                        <v-divider></v-divider>
                        <v-row no-gutters>
                          <v-checkbox
                            v-model="profileSpellingOk"
                            label="I have verified there are no spelling errors."
                            color="#e21e81"
                          >
                          </v-checkbox>
                        </v-row>
                        <v-row no-gutters>
                          <v-checkbox
                            v-model="profileAgeOk"
                            label="I have verified the gender and age of the user
                             match ID documentation."
                            color="#e21e81"
                          >
                          </v-checkbox>
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-row v-if="goodayUserData.gdoEditor != null" class="footer-note">
                          Last updated by GDO staff {{ goodayUserData.gdoEditor }} on
                           {{ dateFormat(goodayUserData.gdoEditTimeStamp) }}
                        </v-row>
                        <v-row v-else class="footer-note">
                          Last updated by Service Provider on
                           {{ dateFormat(goodayUserData.lastUpdate) }}
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions v-if="!profileUpdateInProgress">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="#E21E81"
                        text
                        @click="profileEditClose"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="#E21E81"
                        text
                        @click="profileEditUpdate"
                        :disabled="!readyForProfileUpdate"
                      >
                        Update
                      </v-btn>
                    </v-card-actions>
                    <v-progress-linear
                      v-else
                      indeterminate
                      rounded
                      height="6"
                      color="#E21E81"
                    ></v-progress-linear>
                  </v-card>
                </v-dialog>
          </v-card>
        </v-col>
        <v-col v-if="goodayUserData != null" class="mx-2">
          <v-card v-if="goodayUserData.serviceProvider"
           width="300px" class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              ID Document
            </v-card-title>
            <v-card-text>
              <div @click="show" >
                <div class="images"  v-viewer="{movable: false}">
                  <div class="fill"   >
                    <img  width="300"  :src=goodayUserData.identificationPic />
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-icon color="#FFFFFF" @click="editIdDoc">mdi-account-edit</v-icon>
            </v-card-actions>
          </v-card>
          <v-dialog
              v-model="idDocUpdateDialog"
              max-width="600px">
              <v-card>
                <v-card-title>
                  <v-row align="center" justify="center">
                    <span class="headline">Identification Doc Update</span>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row black class="image-section">
                    <image-cropping
                      formLabel="Picture ID Document"
                      v-on:listingPicture="idImageData = $event"
                    ></image-cropping>
                    <span v-if="$v.idImageData.$invalid"
                      style="color: lime; font-size:12px" mb-4>
                      <v-icon small color="amber">mdi-alert</v-icon>
                      ID picture is required
                    </span>
                  </v-row>
                </v-card-text>
                <v-card-actions v-if="!updateInProgress" class = "justify-center">
                  <v-btn
                    :disabled="idImageData === null"
                    color="#e21e81"
                    style="color: white"
                    @click="updateIDDoc">
                    update
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  v-else
                  indeterminate
                  rounded
                  height="6"
                  color="#E21E81"
                ></v-progress-linear>
              </v-card>
            </v-dialog>
        </v-col>
        <v-col v-if="goodayUserData != null" class="mx-2">
          <v-card  v-if="goodayUserData.serviceProvider"
            width="300px" class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              Gooday Ad
            </v-card-title>
            <v-img  max-height="200" max-width="300" v-if="serviceProviderData !== null"
             :src=serviceProviderData.pictureURL contain>
            </v-img>
            <v-card-text v-if="!goodayAdNotFound && serviceProviderData !== null">
              <v-row>
                <div style="color:white">
                <h4> Summary</h4>
                </div>
                <div style="color:white"> {{ serviceProviderData.summary }} </div>
              </v-row>
              <v-row>
                <div style="color:white">
                <h4> Services </h4>
                </div>
              </v-row>
              <v-row>
                <div>
                    <li style="color:white" v-for="s in serviceProviderData.services" :key="s">
                      {{ s }}
                    </li>
                  </div>
              </v-row>
              <v-row v-if="adIsDisabled">
              <span style="color:white">
                <v-icon small color="amber">
                  mdi-alert
                </v-icon>
                Ad Disabled by {{ serviceProviderData.gdoDisabledAd.gdoEditor }}
                  - {{ serviceProviderData.gdoDisabledAd.gdoAdDisableReason }}
              </span>
              </v-row>
              <!-- <v-row>
                <div style="color:white">
                <h4> Last Seen Location </h4>
                </div>
              </v-row> -->
              <!-- <v-row >
                <GmapMap
                  :center="recentLocation"
                  :zoom="13"
                  map-type-id="roadmap"
                  style="width: 100%; height: 200px"
                >
                  <GmapMarker
                    :position="recentLocation"
                    @click="center=recentLocation"
                  />
                </GmapMap>
              </v-row> -->
            </v-card-text>
            <v-card-text v-else>
              <v-row>
              <div style="color:white">
               <h4> Gooday Ad Not Found</h4>
              </div>
              <div style="color:amber"> User has not published Gooday Ad </div>
            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-icon v-if="!adIsDisabled" color="#FFFFFF" @click="offlineGigWorker">
                mdi-pause-octagon
              </v-icon>
              <v-spacer></v-spacer>
              <v-icon color="#FFFFFF" @click="editAd">mdi-account-edit</v-icon>
            </v-card-actions>
                <v-dialog
                  v-model="providerDisableDialog"
                  max-width="600px">
                  <v-card>
                    <v-card-title>
                      <v-row align="center" justify="center">
                        <span class="headline">Disabling Service Provider Ad</span>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row align="center" justify="center" style="color:#E21E81">
                          <v-icon color="#E21E81">mdi-alert-octagon</v-icon>
                            You are disabling a service provider Ad, once disabled the
                             service provider will not be visible to employers and
                              call center search results!
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-row align="center" justify="center">
                          Reason for disabling service provider
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col
                          >
                            <v-textarea
                              v-model="providerDisableReason"
                              color="#E21E81"
                              label="Reason for disabling service provider"
                              :counter="200"
                              filled
                              required
                              single-line
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-checkbox
                            v-model="disableProviderConfirmation"
                            label="Confrim Disabling Service Provider"
                            color="#e21e81"
                          >
                          </v-checkbox>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions v-if="!providerDisableInProgress">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="#E21E81"
                        text
                        @click="providerDisableDialogClose"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="#E21E81"
                        text
                        @click="disableServiceProvider"
                        :disabled="!readyForDisablingServiceProvider"
                      >
                        Disable Provider
                      </v-btn>
                    </v-card-actions>
                    <v-progress-linear
                      v-else
                      indeterminate
                      rounded
                      height="6"
                      color="#E21E81"
                    ></v-progress-linear>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialog"
                  max-width="600px">
                  <v-card>
                    <v-card-title>
                      <v-row align="center" justify="center">
                      <span class="headline">Updating Service Provider Ad</span>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row align="center" justify="center" style="color:#E21E81">
                          <v-icon color="#E21E81">mdi-alert-octagon</v-icon>
                            You are editing a service provider Ad, make sure there are
                          no spelling mistakes or service mismatch!
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-row align="center" justify="center">
                          Ad Summary
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col
                          >
                            <v-textarea
                              v-model="updatedGoodayAd"
                              color="#E21E81"
                              label="Gooday Ad Summary"
                              :counter="200"
                              filled
                              required
                              single-line
                              :error-messages="summaryErrors"
                              @load="$v.updatedGoodayAd.$touch()"
                              @input="$v.updatedGoodayAd.$touch()"
                              @blur="$v.updatedGoodayAd.$touch()"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-checkbox
                            v-model="editAdPicture"
                            label="Edit Ad Picture"
                            color="#e21e81"
                            @click="adImageData = null"
                          >
                          </v-checkbox>
                        </v-row>
                        <v-row v-if="editAdPicture" black class="image-section">
                          <image-cropping
                            :error-messages="AdListingPictureErrors"
                            @load="$v.adImageData.$touch()"
                            @input="$v.adImageData.$touch()"
                            @blur="$v.adImageData.$touch()"
                            formLabel="Ad Listing picture"
                            v-on:listingPicture="adImageData = $event"
                          ></image-cropping>
                          <span v-if="$v.adImageData.$invalid"
                            style="color: lime; font-size:12px" mb-4>
                            <v-icon small color="amber">mdi-alert</v-icon>
                            Ad picture is required
                          </span>
                        </v-row>
                        <v-row align="center" justify="center">
                          Services
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-chip-group
                            label="Services (maximum 3)"
                            multiple
                            max="3"
                            min="1"
                            column
                            v-model="updatedServices"
                            active-class="selected-service"
                            :error-messages="serviceErrors"
                            @load="$v.updatedServices.$touch()"
                            @input="$v.updatedServices.$touch()"
                            @blur="$v.updatedServices.$touch()"
                            @click="$v.updatedServices.$touch()"
                            >
                            <v-chip v-for="service in goodayServices"
                             :key="service">{{ service }}</v-chip>
                          </v-chip-group>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-4">
                          Certification of accuracy and due diligence
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-checkbox
                            v-model="spellingOk"
                            label="I have verified there are no spelling
                             or grammar error in the updated Ad summary."
                            color="#e21e81"
                          >
                          </v-checkbox>
                        </v-row>
                        <v-row>
                          <v-checkbox
                            v-model="serviceCategoryOk"
                            label="I have verified the selected service categories are correct."
                            color="#e21e81"
                          >
                          </v-checkbox>
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-container v-if="serviceProviderData != null">
                          <v-row v-if="serviceProviderData.gdoEditor != null" class="footer-note">
                          Last updated by GDO staff {{ serviceProviderData.gdoEditor }} on
                           {{ dateFormat(serviceProviderData.gdoEditTimeStamp) }}
                        </v-row>
                        <v-row v-else class="footer-note">
                          Last updated by Service Provider on
                           {{ dateFormat(serviceProviderData.lastUpdate) }}
                        </v-row>
                        </v-container>
                      </v-container>
                    </v-card-text>
                    <v-card-actions v-if="!updateInProgress">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="#E21E81"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="#E21E81"
                        text
                        @click="update"
                        :disabled="!readyForAdUpdate"
                      >
                        Update
                      </v-btn>
                    </v-card-actions>
                    <v-progress-linear
                      v-else
                      indeterminate
                      rounded
                      height="6"
                      color="#E21E81"
                    ></v-progress-linear>
                  </v-card>
                </v-dialog>
          </v-card>
        </v-col>
        <v-col v-if="goodayUserData != null" class="mx-2">
          <v-card v-if="serviceProviderProspects != null && goodayUserData.serviceProvider"
          width="300px" class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              Recent Prospects
            </v-card-title>
            <v-list dark>
              <v-list-item-group>
                <v-list-item v-for="(prospect, i) in serviceProviderProspects" :key="i">
                  <v-list-item-icon>
                    <v-icon v-if="!prospect.serviceProviderReachedOut"
                     color="green">mdi-phone</v-icon>
                    <v-icon v-else color="dark">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content >
                    {{ prospect.prospectFirstName }} {{ prospect.prospectPhoneNumber }}
                    {{ prospectContactDate(prospect.timeStamp) }}
                     {{ prospect.prospectDistance }}kms
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col v-if="goodayUserData != null" class="mx-2">
          <v-card  v-if="!goodayUserData.serviceProvider" width="300px"
           class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              Recent Calls
            </v-card-title>
            <v-list dark>
              <v-list-item-group>
                <v-list-item v-for="(call, i) in goodayUserRecentCalls" :key="i">
                  <v-list-item-icon>
                    <v-icon
                     color="green">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content >
                    {{ call.providerFirstName }} {{ call.providerPhoneNumber }}
                    {{ prospectContactDate(call.timeStamp) }}
                     {{ call.providerDistance }}kms
                     <li style="color:white" v-for="s in call.servicesOfInterest" :key="s">
                      {{ s }}
                     </li>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col v-if="goodayUserData != null" class="mx-2">
          <v-card v-if="goodayUserData.serviceProvider && !userVerificationInProgress" width="300px"
           class="mt-5 mx-auto" color="#e21e81" outlined >
            <v-card-title style="color:white">
              Gooday Verification
            </v-card-title>
            <v-card-text v-if="!goodayUserData.verifiedUser">
              <v-container fluid style="color:white">
                <v-list-item-group>
                  <v-list-item>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="firstNameMatch"
                          color="lime"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col>
                        <div style="color:white">
                          First name matches ID
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="lastNameMatch"
                          color="lime"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col>
                        <div style="color:white">
                          Last name matches ID
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="genderMatch"
                          color="lime"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col>
                        <div style="color:white">
                          Gender matches ID
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="ageMatch"
                          color="lime"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col>
                        <div style="color:white">
                          Age matches ID
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="profilePictureMatch"
                          color="lime"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col>
                        <div style="color:white">
                          Profile picture matches ID
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list-item-group>
              </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn :disabled="!(goodayUserData.serviceProvider
               && !goodayUserData.verifiedUser && readyForVerification)"
                @click="verify">Verify</v-btn>
              <v-btn v-if="goodayUserData.verifiedUser" @click="deVerify">De-Verify</v-btn>
            </v-card-actions>
          </v-card>
          <v-progress-circular
            :size="40"
            :width="7"
            color="amber"
            indeterminate
            v-if="userVerificationInProgress"
          ></v-progress-circular>
        </v-col>
        <v-col class="mx-2">
          <v-card width="300px"
           class="mt-5 mx-auto" color="#e21e81" outlined >
            <v-card-title style="color:white">
              Suspend User
            </v-card-title>
            <v-card-text>
              <v-textarea
                dark
                v-model="userSuspensionNote"
                label="detail note on why this user is getting suspended"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                dark
                :disabled="noToSuspend"
                @click="suspendUser">
                <v-icon>mdi-account-cancel</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="goodayUserData != null" class="mx-2">
          <v-card v-if="goodayUserData.serviceProvider" width="300px"
           class="mt-5 mx-auto" color="#e21e81" outlined >
            <v-card-title style="color:white">
              Fare Data Collection
            </v-card-title>
            <v-card-text>
              <h4 class="my-1 mr-2" style="color: white">
                Service Provider Fare Data Collection</h4>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="serviceProviderData == null"
                dark
                @click="fareCollect">
                <v-icon>mdi-cash-multiple</v-icon>
              </v-btn>
            </v-card-actions>
            <v-dialog
              v-model="fareCollectionDialog"
              max-width="900px">
              <v-card>
                <v-card-title>
                  <v-row align="center" justify="center">
                    <span class="headline">Fare Data Collection</span>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row align="center" justify="center" style="color:#E21E81">
                      <v-icon color="#E21E81" class="mr-4">mdi-cash-multiple</v-icon>
                        <h2>
                          Payment rates expected by service provider
                        </h2>
                    </v-row>
                    <v-container v-if="serviceProviderData !== null">
                      <v-row
                      align="center" justify="center" no-gutters
                      v-if="serviceProviderData.services.length !== 0">
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="2"
                        >
                          <h3 style="color: #e21e81">Service</h3>
                        </v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="2"
                        >
                          <h3 style="color: #e21e81">Experience (Years)</h3>
                        </v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="2"
                        >
                          <h3 style="color: #e21e81">Prefered Employment</h3>
                        </v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="2"
                        >
                          <h3 style="color: #e21e81">Payment (Min)</h3>
                        </v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="2"
                        >
                          <h3 style="color: #e21e81">Payment (Max)</h3>
                        </v-col>
                      </v-row>
                      <v-row v-for="(service,index) in serviceProviderData.services"
                      :key="service"
                      align="center" justify="center" color="#E21E81">
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="2"
                      >{{ service }}</v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="2"
                        >
                          <v-select
                            :items="serviceExperienceYearsList"
                            v-model="serviceExperiences[index]"
                            color="#e21e81"
                            item-color="#e21e81"
                            outlined
                            dense
                          />
                        </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="2"
                      >
                        <v-select
                          :items="serviceEmploymentTypeList"
                          v-model="serviceEmploymentTypes[index]"
                          color="#e21e81"
                          item-color="#e21e81"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="serviceMinFares[index]"
                          color="#e21e81"
                          item-color="#e21e81"
                          outlined
                          dense
                          :rules="numberRules"
                        />
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="serviceMaxFares[index]"
                          color="#e21e81"
                          item-color="#e21e81"
                          outlined
                          dense
                          :rules="numberRules"
                        />
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center" no-gutters>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="3"
                      >
                        Location Preference
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="4"
                      >
                        <VueGooglePlaces
                          id="map"
                          :options="{ fields: ['geometry'] }"
                          types="establishment"
                          country="et"
                          @placechanged="getAddressData"
                          style="width: 100%"
                        >
                          <v-text-field
                            dense
                            hide-details=""
                            label="Enter a location"
                            color="#e21e81"
                            text-color="#e21e81"
                            class="ma-0 pb-7"
                            outlined
                          />
                        </VueGooglePlaces>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center" no-gutters>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="3"
                      >
                        Household Preference
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="4"
                      >
                        <v-select
                          :items="householdTypeList"
                          v-model="serviceProviderHouseHoldPreference"
                          color="#e21e81"
                          item-color="#e21e81"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center" no-gutters>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="3"
                      >
                        Religion Preference
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="4"
                      >
                        <v-select
                          :items="religionPreferenceList"
                          v-model="serviceProviderReligionPreference"
                          color="#e21e81"
                          item-color="#e21e81"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    </v-container>
                  </v-container>
                </v-card-text>
                <v-card-actions class = "justify-center">
                  <v-btn
                    color="#e21e81"
                    style="color: white"
                    @click="updateFareData">
                    update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col class="mx-2" v-if="goodayUserData != null">
          <v-card v-if="goodayUserData.serviceProvider" width="300px"
           class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              Priority Promotion
            </v-card-title>
            <v-card-text>
              <h4 class="my-1 mr-2" style="color:white">
                High Quality Service Provider Promotion
              </h4>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="serviceProviderData == null || !goodayUserData.verifiedUser"
                dark
                @click="providerQualityPromotion"
              >
                <v-icon>mdi-quality-high</v-icon>
              </v-btn>
            </v-card-actions>
            <v-dialog
              v-model="qualityPromotionDialog"
              max-width="900px">
                <v-card>
                  <v-card-title>
                    <v-row align="center" justify="center">
                      <span class="headline">Service Provider Quality Promotion</span>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row align="center" justify="center" style="color:#E21E81">
                        <v-icon color="#E21E81" class="mr-4">mdi-school</v-icon>
                        <h2>
                          Partner Training Institute
                        </h2>
                      </v-row>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col
                        class="d-flex"
                        cols="12"
                        md="3"
                        >
                          Service Provider's Training Profile
                        </v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="4"
                        >
                          <v-select
                            v-model="providerTrainingPartners"
                            :items="availableGoodayPartnersIds"
                            color="#e21e81"
                            item-color="#e21e81"
                            label="Training Profile"
                            multiple
                            chips
                            hint="Select Training Partners"
                            persistent-hint
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center" style="color:#E21E81"
                        class="mt-4 mb-2" v-if="providerTrainingPartners.length != 0">
                        <v-icon color="#E21E81" class="mr-4">mdi-certificate</v-icon>
                        <h2>Certificate of Competency</h2>
                      </v-row>
                      <v-row v-for="t in providerTrainingPartners" :key="t"
                        align="center" justify="center" no-gutters>
                        <v-col
                        class="d-flex"
                        cols="12"
                        md="3"
                        >
                          {{ trainingPartnerLongName(t) }}
                        </v-col>
                        <v-col
                          class="d-flex image-section mb-2"
                          cols="12"
                          md="4"
                          black
                        >
                          <image-cropping
                          formLabel="Cert. of Competency"
                          v-on:listingPicture="cocPicData[t] = $event"
                          ></image-cropping>
                        </v-col>
                       </v-row>
                       <v-row align="center" justify="center" style="color:#E21E81"
                        class="mt-4 mb-2">
                        <v-icon color="#E21E81" class="mr-4">mdi-file-certificate</v-icon>
                        <h2>Degree or Diploma (image/pdf)</h2>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Diploma (1)"
                            color="#E21E81"
                            v-model="diploma1File"
                          ></v-file-input>
                        </v-col>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Diploma (2)"
                            color="#E21E81"
                            v-model="diploma2File"
                          ></v-file-input>
                        </v-col>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Diploma (3)"
                            color="#E21E81"
                            v-model="diploma3File"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center" style="color:#E21E81"
                        class="mt-4 mb-2">
                        <v-icon color="#E21E81" class="mr-4">mdi-script</v-icon>
                        <h2>Other Certificates (image/pdf)</h2>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Certificate (1)"
                            color="#E21E81"
                            v-model="certificate1File"
                          ></v-file-input>
                        </v-col>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Certificate (2)"
                            color="#E21E81"
                            v-model="certificate2File"
                          ></v-file-input>
                        </v-col>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Certificate (3)"
                            color="#E21E81"
                            v-model="certificate3File"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center" style="color:#E21E81"
                        class="mt-4 mb-2">
                        <v-icon color="#E21E81" class="mr-4">mdi-file-account</v-icon>
                        <h2>Resume or CV (pdf)</h2>
                      </v-row>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="3"
                          align-center>
                          <v-file-input
                            accept=".pdf"
                            label="Resume"
                            color="#E21E81"
                            v-model="resumeFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center" style="color:#E21E81"
                        class="mt-4 mb-2">
                        <v-icon color="#E21E81" class="mr-4">mdi-file-document</v-icon>
                        <h2>Reference or Recommendation Letters (image/pdf)</h2>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Recommendation (1)"
                            color="#E21E81"
                            v-model="recommendation1File"
                          ></v-file-input>
                        </v-col>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Recommendation (2)"
                            color="#E21E81"
                            v-model="recommendation2File"
                          ></v-file-input>
                        </v-col>
                        <v-col>
                          <v-file-input
                            accept="image/*,.pdf"
                            label="Recommendation (3)"
                            color="#E21E81"
                            v-model="recommendation3File"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      dark
                      class="ma-4"
                      @click="promoteServiceProvider"
                    >
                      update
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col class="mx-2" v-if="goodayUserData != null">
          <v-card v-if="goodayUserData.serviceProvider
           && providerCredentialList.length > 0" width="600px"
           class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              Service Provider Credentials
            </v-card-title>
            <v-card-text>
              <v-carousel>
                <v-carousel-item
                  v-for="(item,i) in providerCredentialList"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  @click=goToCredential(item.src)
                ></v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mx-2" v-if="goodayUserData != null">
          <v-card v-if="goodayUserData.serviceProvider
           && premiumPaymentReceipts.length > 0" width="600px"
           class="mt-5 mx-auto" color="#e21e81" outlined>
            <v-card-title style="color:white">
              Premium Service Payment Receipts
            </v-card-title>
            <v-card-text>
              <v-carousel>
                <v-carousel-item
                  v-for="(item,i) in premiumPaymentReceipts"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  @click=goToCredential(item.src)
                ></v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {
  required, minLength, maxLength, numeric, minValue, maxValue,
} from 'vuelidate/lib/validators';
import firebase, { firestore } from 'firebase/app';
import GMap from './GMap.vue';
// import { availableServices } from '../data/services';
import ImageCropping from './ImageCropping.vue';
import 'viewerjs/dist/viewer.css';
import 'firebase/storage';

export default {
  components: {
    'image-cropping': ImageCropping,
    'google-map': GMap,
  },
  validations: {
    phone: {
      required,
      minLen: minLength(12),
      maxLen: maxLength(13),
    },
    updatedGoodayAd: {
      required,
      maxLen: maxLength(200),
    },
    updatedServices: {
      required,
    },
    adImageData: {
      required,
    },
    idImageData: {
      required,
    },
    profileImageData: {
      required,
    },
    receiptImageData: {
      required,
    },
    firstNameUpdate: {
      required,
      minLength: 2,
    },
    lastNameUpdate: {
      required,
      minLength: 2,
    },
    ageUpdate: {
      required,
      numeric,
      minValue: minValue(14),
      maxValue: maxValue(70),
    },
    preferedServiceProviderNote: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(200),
    },
  },
  data() {
    return {
      phone: '+251',
      center: { lat: 9.005401, lng: 38.763611 },
      goodayServices: null,
      availableGoodayPartners: null,
      providerTrainingPartners: [],
      cocPicData: {},
      spellingOk: false,
      serviceCategoryOk: false,
      profileSpellingOk: false,
      profileAgeOk: false,
      updatedGoodayAd: '',
      currentGoodayAd: null,
      updatedServices: null,
      newLocation: null,
      newLocationConfirm: false,
      userLocationUpdateInProgress: false,
      profileEditDialog: false,
      locationEditDialog: false,
      premiumEditDialog: false,
      paymentMethods: [
        'Cash',
        'Awash Bank Transfer',
        'CBE Bank Transfer',
        'Bank of Abyssinia Transfer',
        'Telebirr Wallet',
        'CBE Birr Wallet',
        'Awash Birr Wallet',
        'Debit/Credit Card',
        'Wegagen HelloCash Wallet',
        'Amole Wallet',
      ],
      selectedPaymentMethod: null,
      paymentConfiguration: null,
      premiumStatusUpdateInprogress: false,
      premiumPaymentConfirm: false,
      selectedPremiumPackageName: null,
      premiumServicePaidAmount: null,
      premiumPaymentReceipts: [],
      profileUpdateInProgress: false,
      nearbyLocation: null,
      firstNameUpdate: null,
      lastNameUpdate: null,
      profilePicURLUpdate: null,
      ageUpdate: null,
      dialog: false,
      genderUpdate: null,
      genders: [
        'Male',
        'Female',
      ],
      fareCollectionDialog: false,
      qualityPromotionDialog: false,
      searchInProgress: false,
      updateInProgress: false,
      promotionInProgress: false,
      inspectMode: false,
      userNotFound: false,
      goodayAdNotFound: false,
      serviceProviderData: null,
      goodayUserData: null,
      serviceProviderReviews: null,
      serviceProviderReports: null,
      serviceProviderProspects: null,
      goodayUserRecentCalls: null,
      firstNameMatch: false,
      lastNameMatch: false,
      genderMatch: false,
      ageMatch: false,
      profilePictureMatch: false,
      userVerificationInProgress: false,
      editAdPicture: false,
      goodayUID: null,
      adImageData: null,
      idImageData: null,
      profileImageData: null,
      receiptImageData: null,
      userIsSuspended: false,
      userSuspensionNote: '',
      customerRequestTrackingId: null,
      serviceEmploymentTypeList: [
        'any',
        'permanent (monthly)',
        'weekly',
        'daily',
        'hourly',
        'task based',
      ],
      serviceExperienceYearsList: [
        '1',
        '2',
        '3',
        '5',
        'more than 5',
      ],
      householdTypeList: ['Single', 'Couple', 'Family'],
      religionPreferenceList: ['any', 'Christian', 'Muslim'],
      serviceProviderReligionPreference: null,
      serviceProviderHouseHoldPreference: null,
      serviceProviderLocationPreference: null,
      serviceEmploymentTypes: {
        0: '',
        1: '',
        2: '',
      },
      serviceMinFares: {
        0: 0,
        1: 0,
        2: 0,
      },
      serviceMaxFares: {
        0: 0,
        1: 0,
        2: 0,
      },
      serviceExperiences: {
        0: '',
        1: '',
        2: '',
      },
      numberRules: [
        (v) => !!v || 'Payment is required',
        // eslint-disable-next-line no-restricted-globals
        (v) => (!isNaN(parseFloat(v)) && v >= 0) || 'Should be number',
      ],
      diploma1File: null,
      diploma2File: null,
      diploma3File: null,
      resumeFile: null,
      recommendation1File: null,
      recommendation2File: null,
      recommendation3File: null,
      certificate1File: null,
      certificate2File: null,
      certificate3File: null,
      providerDisableDialog: false,
      providerDisableInProgress: false,
      providerDisableReason: null,
      disableProviderConfirmation: false,
      preferedServiceProvider: false,
      preferedServiceProviderNote: null,
      providerCredentialList: [],
      idDocUpdateDialog: false,
      onboardingAgent: null,
      editProfilePicture: false,
    };
  },
  methods: {
    goToCredential(url) {
      window.open(url);
    },
    editProfile() {
      this.profileImageData = null;
      this.editProfilePicture = false;
      this.firstNameUpdate = this.goodayUserData.firstName;
      this.lastNameUpdate = this.goodayUserData.lastName;
      this.profilePicURLUpdate = this.goodayUserData.profilePic;
      this.ageUpdate = this.goodayUserData.age;
      this.genderUpdate = this.goodayUserData.sex;
      this.profileSpellingOk = false;
      this.profileAgeOk = false;
      this.preferedServiceProvider = this.goodayUserData.preferedServiceProvider == null
        ? false
        : this.goodayUserData.preferedServiceProvider;
      this.preferedServiceProviderNote = this.goodayUserData.preferedServiceProviderNote == null
        ? ''
        : this.goodayUserData.preferedServiceProviderNote;
      this.profileEditDialog = true;
    },
    editLocation() {
      this.locationEditDialog = true;
    },
    updateLocation() {
      const db = firebase.firestore();
      const locationPoint = new firebase.firestore.GeoPoint(this.newLocation.lat,
        this.newLocation.lng);
      console.log(locationPoint);
      const timeNow = new Date();
      this.userLocationUpdateInProgress = true;
      db.collection('users').doc(this.goodayUID)
        .set({
          geoLoc: {
            geohash: this.newLocation.geohash,
            // eslint-disable-next-line max-len
            geopoint: locationPoint,
          },
          manualLocationUpdate: {
            gdoEditor: this.gdoAgent,
            gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
          },
        },
        { merge: true })
        .then(() => {
          db.collection('goodays').doc(this.goodayUID)
            .set({
              geoLoc: {
                geohash: this.newLocation.geohash,
                // eslint-disable-next-line max-len
                geopoint: locationPoint,
              },
            },
            { merge: true })
            .then(() => {
              this.userLocationUpdateInProgress = false;
              this.locationEditDialog = false;
              this.lookUp();
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
      this.locationEditDialog = false;
    },
    getNewAddressData(addressData) {
      // console.log(addressData);
      this.center = { lat: addressData.latitude, lng: addressData.longitude };
      this.newLocation = { lat: addressData.latitude, lng: addressData.longitude };
      console.log(this.newLocation);
    },
    premiumStatusUpdate() {
      this.receiptImageData = null;
      this.selectedPremiumPackageName = null;
      this.selectedPaymentMethod = null;
      this.premiumServicePaidAmount = null;
      this.premiumEditDialog = true;
    },
    cancelPremiumPayment() {
      this.premiumEditDialog = false;
      this.receiptImageData = null;
      this.selectedPremiumPackageName = null;
      this.selectedPaymentMethod = null;
      this.premiumServicePaidAmount = null;
      this.receiptImageData = null;
    },
    editIdDoc() {
      this.idImageData = null;
      this.updateInProgress = false;
      this.idDocUpdateDialog = true;
    },
    editAd() {
      this.currentGoodayAd = this.serviceProviderData == null ? '' : this.serviceProviderData.summary;
      this.mapServicesEditing();
      this.spellingOk = false;
      this.serviceCategoryOk = false;
      this.updatedGoodayAd = this.currentGoodayAd;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.adImageData = null;
    },
    idUpdateDialogClose() {
      this.idDocUpdateDialog = false;
      this.idImageData = null;
    },
    providerDisableDialogClose() {
      this.providerDisableDialog = false;
    },
    suspendUser() {
      const db = firebase.firestore();
      const timeNow = new Date();
      db.collection('suspendedUsers')
        .doc(this.goodayUID)
        .set({
          note: this.userSuspensionNote,
          timeStamp: firestore.Timestamp.fromDate(timeNow),
          suspendedBy: firebase.auth().currentUser.uid,
        }, { merge: true })
        .then(() => {
          this.userIsSuspended = true;
        })
        .catch((error) => console.log(error));
    },
    offlineGigWorker() {
      this.providerDisableReason = null;
      this.providerDisableDialog = true;
    },
    async profileEditUpdate() {
      this.profileUpdateInProgress = true;
      const db = firebase.firestore();
      const storageRef = firebase.storage().ref();
      const imageName = 'userData.jpeg';
      const timeNow = new Date();
      if (this.editProfilePicture) {
        await this.profileImageData.toBlob(async (imageBlob) => {
          const uploadTask = storageRef.child(`profile/${this.goodayUID}/${imageName}`).put(imageBlob);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
              // Get task progress, including the number of bytes uploaded and
              //   the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // eslint-disable-next-line no-console
              console.log(`Upload is ${progress}% done`);
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  // eslint-disable-next-line no-console
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  // eslint-disable-next-line no-console
                  console.log('Upload is running');
                  break;
                case firebase.storage.TaskState.ERROR:
                  console.log('Error occured uploading image data');
                  break;
                case firebase.storage.TaskState.SUCCESS:
                  console.log('Upload is successful');
                  break;
                default:
                  console.log('something weird happened');
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  // eslint-disable-next-line no-console
                  console.log('user doesnt have permission');
                  break;

                case 'storage/canceled':
                  // User canceled the upload
                  // eslint-disable-next-line no-console
                  console.log('upload is canceled');
                  break;

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  // eslint-disable-next-line no-console
                  console.log('unknown error');
                  break;
                default:
                  console.log('unexpected error occured');
                  break;
              }
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL()
                .then((profileURL) => {
                  this.profilePicURLUpdate = profileURL;
                  const profileUpdate = {
                    firstName: this.firstNameUpdate,
                    lastName: this.lastNameUpdate,
                    sex: this.genderUpdate,
                    profilePic: this.profilePicURLUpdate === null
                      ? this.goodayUserData.profilePic : this.profilePicURLUpdate,
                    age: parseInt(this.ageUpdate, 10),
                    gdoEditor: this.gdoAgent,
                    gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
                    preferedServiceProvider: this.preferedServiceProvider,
                    preferedServiceProviderNote: this.preferedServiceProviderNote,
                    preferedStatusUpdate: {
                      gdoAdmin: this.gdoAgent,
                      timeStamep: firestore.Timestamp.fromDate(timeNow),
                    },
                  };
                  const adUpdate = {
                    firstName: this.firstNameUpdate,
                    lastName: this.lastNameUpdate,
                    profilePicURL: this.profilePicURLUpdate === null
                      ? this.goodayUserData.profilePic : this.profilePicURLUpdate,
                    gdoEditor: this.gdoAgent,
                    gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
                    preferedServiceProvider: this.preferedServiceProvider,
                    preferedServiceProviderNote: this.preferedServiceProviderNote,
                    preferedStatusUpdate: {
                      gdoAdmin: this.gdoAgent,
                      timeStamep: firestore.Timestamp.fromDate(timeNow),
                    },
                  };
                  db.collection('users')
                    .doc(this.goodayUID)
                    .set(profileUpdate, { merge: true })
                    .then(() => {
                      db.collection('goodays')
                        .doc(this.goodayUID)
                        .set(adUpdate, { merge: true })
                        .then(() => {
                          this.lookUp();
                          this.profileUpdateInProgress = false;
                          this.profileEditClose();
                        })
                        .catch((error) => console.log(error));
                    })
                    .catch((error) => console.log(error));
                })
                .catch((error) => console.log(error));
            },
          );
        });
      } else {
        const profileUpdate = {
          firstName: this.firstNameUpdate,
          lastName: this.lastNameUpdate,
          sex: this.genderUpdate,
          profilePic: this.profilePicURLUpdate === null
            ? this.goodayUserData.profilePic : this.profilePicURLUpdate,
          age: parseInt(this.ageUpdate, 10),
          gdoEditor: this.gdoAgent,
          gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
          preferedServiceProvider: this.preferedServiceProvider,
          preferedServiceProviderNote: this.preferedServiceProviderNote,
          preferedStatusUpdate: {
            gdoAdmin: this.gdoAgent,
            timeStamep: firestore.Timestamp.fromDate(timeNow),
          },
        };
        const adUpdate = {
          firstName: this.firstNameUpdate,
          lastName: this.lastNameUpdate,
          profilePicURL: this.profilePicURLUpdate === null
            ? this.goodayUserData.profilePic : this.profilePicURLUpdate,
          gdoEditor: this.gdoAgent,
          gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
          preferedServiceProvider: this.preferedServiceProvider,
          preferedServiceProviderNote: this.preferedServiceProviderNote,
          preferedStatusUpdate: {
            gdoAdmin: this.gdoAgent,
            timeStamep: firestore.Timestamp.fromDate(timeNow),
          },
        };
        db.collection('users')
          .doc(this.goodayUID)
          .set(profileUpdate, { merge: true })
          .then(() => {
            db.collection('goodays')
              .doc(this.goodayUID)
              .set(adUpdate, { merge: true })
              .then(() => {
                this.lookUp();
                this.profileUpdateInProgress = false;
                this.profileEditClose();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    },
    profileEditClose() {
      this.profileEditDialog = false;
    },
    updateIDDoc() {
      this.updateInProgress = true;
      const imageName = 'userData.jpeg';
      const db = firebase.firestore();
      const storageRef = firebase.storage().ref();
      const timeNow = new Date();
      console.log({
        summary: this.updatedGoodayAd,
        gdoEditor: this.gdoAgent,
        gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
      });
      this.idImageData.toBlob(async (imageBlob) => {
        const uploadTask = storageRef.child(`identification/${this.goodayUID}/${imageName}`).put(imageBlob);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            // Get task progress, including the number of bytes uploaded and
            //   the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // eslint-disable-next-line no-console
            console.log(`Upload is ${progress}% done`);
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // eslint-disable-next-line no-console
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // eslint-disable-next-line no-console
                console.log('Upload is running');
                break;
              case firebase.storage.TaskState.ERROR:
                console.log('Error occured uploading image data');
                break;
              case firebase.storage.TaskState.SUCCESS:
                console.log('Upload is successful');
                break;
              default:
                console.log('something weird happened');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                // eslint-disable-next-line no-console
                console.log('user doesnt have permission');
                break;

              case 'storage/canceled':
                // User canceled the upload
                // eslint-disable-next-line no-console
                console.log('upload is canceled');
                break;

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                // eslint-disable-next-line no-console
                console.log('unknown error');
                break;
              default:
                console.log('unexpected error occured');
                break;
            }
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL()
              .then((idURL) => {
                db.collection('users').doc(this.goodayUID).set({
                  idDocUpdate: {
                    gdoEditor: this.gdoAgent,
                    timeStamp: firestore.Timestamp.fromDate(timeNow),
                  },
                  identificationPic: idURL,
                }, { merge: true })
                  .then(() => {
                    this.lookUp();
                    this.updateInProgress = false;
                    this.lookUp();
                    this.idUpdateDialogClose();
                  })
                  .catch();
              })
              .catch((error) => console.log(error));
          },
        );
      });
    },
    disableServiceProvider() {
      this.providerDisableInProgress = true;
      const db = firebase.firestore();
      const timeNow = new Date();
      db.collection('goodays')
        .doc(this.goodayUID)
        .set({
          services: [],
          gdoDisabledAd: {
            gdoEditor: this.gdoAgent,
            gdoAdDisableReason: this.providerDisableReason,
            gdoAdDisableTimeStamp: firestore.Timestamp.fromDate(timeNow),
          },
        }, { merge: true })
        .then(() => {
          this.lookUp();
          this.providerDisableInProgress = false;
          this.providerDisableDialogClose();
        })
        .catch((error) => console.log(error));
    },
    removeCocPriority() {
      this.profileUpdateInProgress = true;
      const db = firebase.firestore();
      const timeNow = new Date();
      db.collection('goodays')
        .doc(this.goodayUID)
        .set({
          priorityUpdate: {
            gdoAdmin: this.gdoAgent,
            timeStamp: firestore.Timestamp.fromDate(timeNow),
          },
          coc: false,
        }, { merge: true })
        .then(() => {
          db.collection('users')
            .doc(this.goodayUID)
            .set({
              priorityUpdate: {
                gdoAdmin: this.gdoAgent,
                timeStamep: firestore.Timestamp.fromDate(timeNow),
              },
              coc: false,
            }, { merge: true })
            .then(() => {
              this.lookUp();
              this.profileUpdateInProgress = false;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    removeQualityPriority() {
      this.profileUpdateInProgress = true;
      const db = firebase.firestore();
      const timeNow = new Date();
      db.collection('goodays')
        .doc(this.goodayUID)
        .set({
          priorityUpdate: {
            gdoAdmin: this.gdoAgent,
            timeStamep: firestore.Timestamp.fromDate(timeNow),
          },
          qualityProvider: false,
        }, { merge: true })
        .then(() => {
          db.collection('users')
            .doc(this.goodayUID)
            .set({
              priorityUpdate: {
                gdoAdmin: this.gdoAgent,
                timeStamep: firestore.Timestamp.fromDate(timeNow),
              },
              qualityProvider: false,
            }, { merge: true })
            .then(() => {
              this.lookUp();
              this.profileUpdateInProgress = false;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    removePreferedFlag() {
      this.profileUpdateInProgress = true;
      const db = firebase.firestore();
      const timeNow = new Date();
      db.collection('goodays')
        .doc(this.goodayUID)
        .set({
          preferedStatusUpdate: {
            gdoAdmin: this.gdoAgent,
            timeStamep: firestore.Timestamp.fromDate(timeNow),
          },
          preferedServiceProvider: false,
          preferedServiceProviderNote: null,
        }, { merge: true })
        .then(() => {
          db.collection('users')
            .doc(this.goodayUID)
            .set({
              preferedStatusUpdate: {
                gdoAdmin: this.gdoAgent,
                timeStamep: firestore.Timestamp.fromDate(timeNow),
              },
              preferedServiceProvider: false,
              preferedServiceProviderNote: null,
            }, { merge: true })
            .then(() => {
              this.lookUp();
              this.profileUpdateInProgress = false;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    update() {
      this.updateInProgress = true;
      const imageName = 'userData.jpeg';
      const db = firebase.firestore();
      const storageRef = firebase.storage().ref();
      const newServices = [];
      this.updatedServices.forEach((e) => newServices.push(this.goodayServices[e]));
      const timeNow = new Date();
      console.log({
        summary: this.updatedGoodayAd,
        services: newServices,
        gdoEditor: this.gdoAgent,
        gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
      });
      const adPayLoad = this.serviceProviderData == null ? {
        approvedUser: this.goodayUserData.verifiedUser,
        createdAt: firestore.Timestamp.fromDate(timeNow),
        firstName: this.goodayUserData.firstName,
        geoLoc: this.goodayUserData.geoLoc,
        lastName: this.goodayUserData.lastName,
        lastUpdate: firestore.Timestamp.fromDate(timeNow),
        phoneNumber: this.goodayUserData.phoneNumber,
        premiumProvider: this.goodayUserData.premiumProvider == null ? false
          : this.goodayUserData.premiumProvider,
        policyAgree: true,
        profilePicURL: this.goodayUserData.profilePic,
        summary: this.updatedGoodayAd,
        services: newServices,
        gdoEditor: this.gdoAgent,
        gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
      } : {
        summary: this.updatedGoodayAd,
        premiumProvider: this.goodayUserData.premiumProvider == null ? false
          : this.goodayUserData.premiumProvider,
        services: newServices,
        gdoEditor: this.gdoAgent,
        gdoEditTimeStamp: firestore.Timestamp.fromDate(timeNow),
      };
      db.collection('goodays')
        .doc(this.goodayUID)
        .set(adPayLoad, { merge: true })
        .then(() => {
          if (this.editAdPicture === true) {
            this.adImageData.toBlob(async (imageBlob) => {
              const uploadTask = storageRef.child(`gooday/${this.goodayUID}/${imageName}`).put(imageBlob);
              uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and
                  //   the total number of bytes to be uploaded
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  // eslint-disable-next-line no-console
                  console.log(`Upload is ${progress}% done`);
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      // eslint-disable-next-line no-console
                      console.log('Upload is paused');
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      // eslint-disable-next-line no-console
                      console.log('Upload is running');
                      break;
                    case firebase.storage.TaskState.ERROR:
                      console.log('Error occured uploading image data');
                      break;
                    case firebase.storage.TaskState.SUCCESS:
                      console.log('Upload is successful');
                      break;
                    default:
                      console.log('something weird happened');
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case 'storage/unauthorized':
                      // User doesn't have permission to access the object
                      // eslint-disable-next-line no-console
                      console.log('user doesnt have permission');
                      break;

                    case 'storage/canceled':
                      // User canceled the upload
                      // eslint-disable-next-line no-console
                      console.log('upload is canceled');
                      break;

                    case 'storage/unknown':
                      // Unknown error occurred, inspect error.serverResponse
                      // eslint-disable-next-line no-console
                      console.log('unknown error');
                      break;
                    default:
                      console.log('unexpected error occured');
                      break;
                  }
                },
                () => {
                  uploadTask.snapshot.ref.getDownloadURL()
                    .then((adURL) => {
                      db.collection('goodays').doc(this.goodayUID).set({
                        pictureURL: adURL,
                      }, { merge: true });
                      this.lookUp();
                    })
                    .catch((error) => console.log(error));
                },
              );
            });
          }
          this.lookUp();
          this.updateInProgress = false;
          this.close();
        })
        .catch((error) => console.log(error));
    },
    getCredentials() {
      const storageRef = firebase.storage().ref();
      // Create a reference under which you want to list
      const listRef = storageRef.child(`credentials/${this.goodayUID}`);
      // Find all credential files
      listRef.listAll()
        .then((res) => {
          console.log('searching for credentials...');
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            itemRef.getDownloadURL()
              .then((credURL) => {
                this.providerCredentialList.push({ src: credURL });
              })
              .catch((error) => console.log(error));
          });
        }).catch((error) => console.log(error));
    },
    show() {
      const viewer = this.$el.querySelector('.images').$viewer;
      viewer.show();
    },
    dateFormat(date) {
      if (date == null) {
        return 'N/A';
      }
      const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      // eslint-disable-next-line prefer-template
      return (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + '-' + y;
    },
    mapServicesEditing() {
      const s = [];
      if (this.serviceProviderData != null) {
        this.serviceProviderData.services.forEach((e) => s.push(this.goodayServices.indexOf(e)));
        this.updatedServices = [...s];
      } else {
        this.updatedServices = [];
      }
    },
    prospectContactDate(pdate) {
      const Date = pdate.toDate();
      const month = Date.getMonth() + 1;
      const day = Date.getDate();
      const year = Date.getFullYear();
      const hour = Date.getHours();
      const minute = Date.getMinutes();
      return `${month}/${day}/${year} ${hour}:${minute}`;
    },
    lookUp() {
      this.providerCredentialList = [];
      this.premiumPaymentReceipts = [];
      this.$store.dispatch('auth/persistSession');
      const db = firebase.firestore();
      this.inspectMode = true;
      this.serviceProviderData = null;
      // console.log('lookup started');
      // user data from phone number
      db.collection('users').where('phoneNumber', '==', this.phone)
        .get()
        .then((userSnapShot) => {
          // console.log(userSnapShot.docs[0].data());
          this.searchInProgress = true;
          if (userSnapShot.size === 1) {
            // console.log('user is found');
            this.userNotFound = false;
            this.goodayUserData = userSnapShot.docs[0].data();
            this.goodayUID = userSnapShot.docs[0].id;

            // check if user is suspended
            db.collection('suspendedUsers')
              .doc(this.goodayUID)
              .get()
              .then((blockRef) => {
                if (blockRef.exists) {
                  this.userIsSuspended = true;
                  this.userSuspensionNote = blockRef.data().note;
                } else {
                  this.userIsSuspended = false;
                  this.userSuspensionNote = '';
                }
              })
              .catch();
            if (this.goodayUserData.serviceProvider) {
              // gooday ad from phone number
              db.collection('goodays')
                .where('phoneNumber', '==', this.phone)
                .get()
                .then((querySnapShot) => {
                  // console.log('Number of snapshots', '=>', querySnapShot.size);
                  if (querySnapShot.size === 1) {
                    this.serviceProviderData = querySnapShot.docs[0].data();
                    if (this.serviceProviderData.services == null) {
                      this.goodayAdNotFound = true;
                      this.serviceProviderData.services = [];
                    } else {
                      this.goodayAdNotFound = false;
                    // console.log(this.serviceProviderData);
                    }
                    this.getPreviousPaymentReceipts();
                    // prospects from uid
                    db.collection('prospects').doc(querySnapShot.docs[0].id).collection('prospects')
                      .orderBy('timeStamp', 'desc')
                      .limit(25)
                      .get()
                      .then((prospectSnapShot) => {
                        // console.log('Number of prospect snapshots', '=>', prospectSnapShot.size);
                        const prospectList = [];
                        prospectSnapShot.forEach((prospect) => prospectList.push(prospect.data()));
                        this.serviceProviderProspects = prospectList;
                        this.getCredentials();
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    this.goodayAdNotFound = true;
                  }
                  if (this.goodayUserData.onboardingAgentBadgeNumber != null) {
                    db.collection('gdoAdmins').doc(this.goodayUserData.onboardingAgentBadgeNumber)
                      .get()
                      .then((adminSnapShot) => {
                        this.onboardingAgent = adminSnapShot.data().email;
                      })
                      .catch((error) => console.log(error));
                  }
                  this.searchInProgress = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              db.collection('connections').doc(userSnapShot.docs[0].id).collection('calls')
                .orderBy('timeStamp', 'desc')
                .limit(25)
                .get()
                .then((callsSnapShot) => {
                  // console.log('Number of recent call snapshots', '=>', callsSnapShot.size);
                  const recentCallsList = [];
                  callsSnapShot.forEach((call) => recentCallsList.push(call.data()));
                  this.goodayUserRecentCalls = recentCallsList;
                  this.searchInProgress = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.userNotFound = true;
            this.searchInProgress = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verify() {
      const db = firebase.firestore();
      this.userVerificationInProgress = true;
      this.resetVerificationCheckList();
      // console.log('verification started...');
      const dateNow = new Date();
      db.collection('users').doc(this.goodayUID)
        .set({
          verifiedUser: true,
          verificationUpdatedAt: firestore.Timestamp.fromDate(
            dateNow,
          ),
          verificationUpdatedBy: firebase.auth().currentUser.uid,
        }, { merge: true })
        .then(() => {
          db.collection('goodays').doc(this.goodayUID)
            .update({
              approvedUser: true,
            })
            .then(() => {
              // console.log('verification is complete');
              this.userVerificationInProgress = false;
              this.inspectMode = false;
            })
            .catch((error) => {
              this.userVerificationInProgress = false;
              this.inspectMode = false;
              console.log(error);
            });
        })
        .catch((error) => {
          this.userVerificationInProgress = false;
          this.inspectMode = false;
          console.log(error);
        });
    },
    deVerify() {
      const db = firebase.firestore();
      this.userVerificationInProgress = true;
      this.resetVerificationCheckList();
      // console.log('de-verification started...');
      const dateNow = new Date();
      db.collection('users').doc(this.goodayUID)
        .set({
          verifiedUser: false,
          verificationUpdatedAt: firestore.Timestamp.fromDate(
            dateNow,
          ),
          verificationUpdatedBy: firebase.auth().currentUser.uid,
        }, { merge: true })
        .then(() => {
          db.collection('goodays').doc(this.goodayUID)
            .update({
              approvedUser: false,
            })
            .then(() => {
              // console.log('de-verification is complete');
              this.userVerificationInProgress = false;
              this.inspectMode = false;
            })
            .catch((error) => {
              this.userVerificationInProgress = false;
              this.inspectMode = false;
              console.log(error);
            });
        })
        .catch((error) => {
          this.userVerificationInProgress = false;
          this.inspectMode = false;
          console.log(error);
        });
    },
    resetVerificationCheckList() {
      this.firstNameMatch = false;
      this.lastNameMatch = false;
      this.ageMatch = false;
      this.genderMatch = false;
      this.profilePictureMatch = false;
    },
    fareCollect() {
      this.fareCollectionDialog = true;
    },
    providerQualityPromotion() {
      this.updateProviderProfile();
      this.diploma1File = null;
      this.diploma2File = null;
      this.diploma3File = null;
      this.resumeFile = null;
      this.recommendation1File = null;
      this.recommendation2File = null;
      this.recommendation3File = null;
      this.certificate1File = null;
      this.certificate2File = null;
      this.certificate3File = null;
      this.qualityPromotionDialog = true;
    },
    getAddressData(addressData) {
      // console.log(addressData);
      this.serviceProviderLocationPreference = {
        geoLoc: {
          lat: addressData.latitude,
          lng: addressData.longitude,
        },
        locationName: addressData.formatted_address,
      };
    },
    updateFareData() {
      const db = firebase.firestore();
      db.collection('fareAndRate')
        .doc(this.goodayUID)
        .set(this.fareData, { merge: false })
        .then(() => {
          this.serviceProviderReligionPreference = null;
          this.serviceProviderHouseHoldPreference = null;
          this.serviceProviderLocationPreference = null;
          this.serviceEmploymentTypes = {
            0: '',
            1: '',
            2: '',
          };
          this.serviceMinFares = {
            0: 0,
            1: 0,
            2: 0,
          };
          this.serviceMaxFares = {
            0: 0,
            1: 0,
            2: 0,
          };
          this.serviceExperiences = {
            0: '',
            1: '',
            2: '',
          };
          this.fareCollectionDialog = false;
        })
        .catch((error) => console.log(error));
    },
    getServices() {
      const db = firebase.firestore();
      db.collection('services')
        .doc('gooday_classified_services')
        .get()
        .then((servicesSnapshot) => {
          const serviceList = [];
          servicesSnapshot.data().services
            .forEach((c) => { serviceList.push(...c.services); });
          this.goodayServices = [...new Set(serviceList)].sort();
        })
        .catch((error) => console.log(error));
    },
    getGoodayTrainingPartners() {
      const db = firebase.firestore();
      const goodayPartnerIds = [];
      db.collection('goodayTrainingPartners')
        .get()
        .then((partnerSnapshot) => {
          partnerSnapshot.forEach((doc) => {
            goodayPartnerIds.push(doc.data());
          });
          this.availableGoodayPartners = [...goodayPartnerIds];
        })
        .catch((error) => console.log(error));
    },
    getPaymentConfiguration() {
      const db = firebase.firestore();
      db.collection('configuration')
        .doc('paymentConfiguration')
        .get()
        .then((partnerSnapshot) => {
          this.paymentConfiguration = partnerSnapshot.data().serviceProvider.packages;
        })
        .catch((error) => console.log(error));
    },
    updateProviderProfile() {
      this.providerTrainingPartners = this.serviceProviderData.trainingPartners == null
        ? [] : [...this.serviceProviderData.trainingPartners];
    },
    trainingPartnerLongName(id) {
      const matchedPartner = this.availableGoodayPartners.find((p) => p.id === id);
      return matchedPartner == null ? 'Non-Partner Training Institute' : matchedPartner.name;
    },
    promoteServiceProvider() {
      this.promotionInProgress = true;
      const credentials = [];
      const db = firebase.firestore();
      const storageRef = firebase.storage().ref();
      const timeNow = new Date();
      // update qualityProvider
      if (this.providerTrainingPartners.length !== 0) {
        db.collection('users')
          .doc(this.goodayUID)
          .set(
            {
              priorityUpdate: {
                gdoAdmin: this.gdoAgent,
                timeStamp: firestore.Timestamp.fromDate(timeNow),
              },
              qualityProvider: true,
              trainingPartners: this.providerTrainingPartners,
            }, { merge: true },
          )
          .then(() => {
            db.collection('goodays')
              .doc(this.goodayUID)
              .set(
                {
                  priorityUpdate: {
                    gdoAdmin: this.gdoAgent,
                    timeStamep: firestore.Timestamp.fromDate(timeNow),
                  },
                  qualityProvider: true,
                  trainingPartners: this.providerTrainingPartners,
                }, { merge: true },
              )
              .then(() => {
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
      this.providerTrainingPartners.forEach((e) => {
        const imageName = `${e}.jpeg`;
        if (this.cocPicData[e] != null) {
          // upload coc, finally update coc field
          this.cocPicData[e].toBlob(async (imageBlob) => {
            const uploadTask = storageRef.child(`credentials/${this.goodayUID}/${imageName}`).put(imageBlob);
            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                // Get task progress, including the number of bytes uploaded and
                //   the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // eslint-disable-next-line no-console
                console.log(`Upload is ${progress}% done`);
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    // eslint-disable-next-line no-console
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    // eslint-disable-next-line no-console
                    console.log('Upload is running');
                    break;
                  case firebase.storage.TaskState.ERROR:
                    console.log('Error occured uploading image data');
                    break;
                  case firebase.storage.TaskState.SUCCESS:
                    console.log('Upload is successful');
                    break;
                  default:
                    console.log('something weird happened');
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    // eslint-disable-next-line no-console
                    console.log('user doesnt have permission');
                    break;

                  case 'storage/canceled':
                    // User canceled the upload
                    // eslint-disable-next-line no-console
                    console.log('upload is canceled');
                    break;

                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    // eslint-disable-next-line no-console
                    console.log('unknown error');
                    break;
                  default:
                    console.log('unexpected error occured');
                    break;
                }
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL()
                  .then((credURL) => {
                    credentials.push({
                      institute: e,
                      credentialURL: credURL,
                    });
                    db.collection('users')
                      .doc(this.goodayUID)
                      .set(
                        {
                          priorityUpdate: {
                            gdoAdmin: this.gdoAgent,
                            timeStamep: firestore.Timestamp.fromDate(timeNow),
                          },
                          coc: true,
                          credentials,
                        }, { merge: true },
                      )
                      .then(() => {
                        db.collection('goodays')
                          .doc(this.goodayUID)
                          .set(
                            {
                              priorityUpdate: {
                                gdoAdmin: this.gdoAgent,
                                timeStamep: firestore.Timestamp.fromDate(timeNow),
                              },
                              coc: true,
                            }, { merge: true },
                          )
                          .then(() => { delete this.cocPicData[e]; })
                          .catch((error) => console.log(error));
                      })
                      .catch((error) => console.log(error));
                  })
                  .catch((error) => console.log(error));
              },
            );
          });
        }
      });
      this.documentsMap.forEach((m) => {
        if (m.fileData != null) {
          const uploadTask = storageRef.child(`credentials/${this.goodayUID}/${m.fileName}.${m.fileData.name.split('.').pop()}`).put(m.fileData);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
              // Get task progress, including the number of bytes uploaded and
              //   the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // eslint-disable-next-line no-console
              console.log(`Upload is ${progress}% done`);
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  // eslint-disable-next-line no-console
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  // eslint-disable-next-line no-console
                  console.log('Upload is running');
                  break;
                case firebase.storage.TaskState.ERROR:
                  console.log('Error occured uploading image data');
                  break;
                case firebase.storage.TaskState.SUCCESS:
                  console.log('Upload is successful');
                  break;
                default:
                  console.log('something weird happened');
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  // eslint-disable-next-line no-console
                  console.log('user doesnt have permission');
                  break;

                case 'storage/canceled':
                  // User canceled the upload
                  // eslint-disable-next-line no-console
                  console.log('upload is canceled');
                  break;

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  // eslint-disable-next-line no-console
                  console.log('unknown error');
                  break;
                default:
                  console.log('unexpected error occured');
                  break;
              }
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL()
                .then(() => {
                  console.log(`${m.fileName}`);
                })
                .catch((error) => console.log(error));
            },
          );
        }
      });
      this.lookUp();
      this.qualityPromotionDialog = false;
      this.cocPicData = {};
      this.providerTrainingPartners = [];
      this.promotionInProgress = false;
    },
    updatePremiumStatus() {
      this.premiumStatusUpdateInprogress = true;
      const db = firebase.firestore();
      const storageRef = firebase.storage().ref();
      const timeNow = new Date();
      const selectedPackage = this.paymentConfiguration
        .find((p) => p.name === this.selectedPremiumPackageName);
      const { ttlDays } = selectedPackage;
      const billDueDate = new Date();
      billDueDate.setDate(billDueDate.getDate() + ttlDays);
      db.collection('billing')
        .doc(this.goodayUID)
        .set(
          {
            billingUpdate: {
              gdoAdmin: this.gdoAgent,
              timeStamp: firestore.Timestamp.fromDate(timeNow),
            },
            paidThrough: firestore.Timestamp.fromDate(billDueDate),
          }, { merge: true },
        )
        .then(() => {
          db.collection('users')
            .doc(this.goodayUID)
            .set({
              premiumProvider: true,
              premiumStatusUpdate: {
                gdoAdmin: this.gdoAgent,
                timeStamp: firestore.Timestamp.fromDate(timeNow),
              },
            }, { merge: true })
            .then(() => {
              db.collection('goodays')
                .doc(this.goodayUID)
                .set({
                  premiumProvider: true,
                  premiumStatusUpdate: {
                    gdoAdmin: this.gdoAgent,
                    timeStamp: firestore.Timestamp.fromDate(timeNow),
                  },
                }, { merge: true })
                .then(() => {
                  this.receiptImageData.toBlob(async (imageBlob) => {
                    const uploadTask = storageRef.child(`Payments/${this.goodayUID}/${Date.now()}`).put(imageBlob);
                    uploadTask.on(
                      firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and
                        //   the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // eslint-disable-next-line no-console
                        console.log(`Upload is ${progress}% done`);
                        switch (snapshot.state) {
                          case firebase.storage.TaskState.PAUSED: // or 'paused'
                            // eslint-disable-next-line no-console
                            console.log('Upload is paused');
                            break;
                          case firebase.storage.TaskState.RUNNING: // or 'running'
                            // eslint-disable-next-line no-console
                            console.log('Upload is running');
                            break;
                          case firebase.storage.TaskState.ERROR:
                            console.log('Error occured uploading image data');
                            break;
                          case firebase.storage.TaskState.SUCCESS:
                            console.log('Upload is successful');
                            break;
                          default:
                            console.log('something weird happened');
                            break;
                        }
                      },
                      (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                          case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            // eslint-disable-next-line no-console
                            console.log('user doesnt have permission');
                            break;

                          case 'storage/canceled':
                            // User canceled the upload
                            // eslint-disable-next-line no-console
                            console.log('upload is canceled');
                            break;

                          case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            // eslint-disable-next-line no-console
                            console.log('unknown error');
                            break;
                          default:
                            console.log('unexpected error occured');
                            break;
                        }
                      },
                      () => {
                        uploadTask.snapshot.ref.getDownloadURL()
                          .then((receiptURL) => {
                            console.log(`${receiptURL}`);
                            db.collection('payment_transactions')
                              .doc(this.goodayUID)
                              .collection('payments')
                              .add({
                                amount: this.premiumServicePaidAmount,
                                paymentDescription: this.selectedPremiumPackageName,
                                timeStamp: firestore.Timestamp.fromDate(timeNow),
                                currency: 'ETB',
                                firstName: this.goodayUserData.firstName,
                                lastName: this.goodayUserData.lastName,
                                paymentSubmittedBy: this.gdoAgent,
                                packageUnits: ttlDays,
                                paymentAmount: this.premiumServicePaidAmount,
                                phoneNumber: this.goodayUserData.phoneNumber,
                                processed: true,
                                invoiceNumber: 'manual',
                                mode: 'GDO',
                                reference: 'manual',
                                serviceProvider: this.goodayUserData.serviceProvider,
                                status: 'success',
                                txReference: 'manual',
                                type: this.selectedPaymentMethod,
                                userId: this.goodayUID,
                              })
                              .then(() => {
                                this.premiumStatusUpdateInprogress = false;
                                this.cancelPremiumPayment();
                                this.lookUp();
                              })
                              .catch((error) => console.log(error));
                          })
                          .catch((error) => console.log(error));
                      },
                    );
                  });
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    getPreviousPaymentReceipts() {
      const storageRef = firebase.storage().ref().child(`Payments/${this.goodayUID}`);
      // List all items in the folder
      storageRef.listAll().then((result) => {
        // Loop through each item and get the URL
        result.items.forEach((itemRef) => {
          itemRef.getDownloadURL().then((url) => {
            this.premiumPaymentReceipts.push({ src: url });
            console.log(url);
          });
        });
      }).catch((error) => {
        console.log('Error listing folder:', error);
      });
    },
  },
  computed: {
    // goodayServices() {
    //   const serviceList =  [...availableServices];
    //   return serviceList;
    // },
    noToSuspend() {
      return this.userIsSuspended
        || this.userSuspensionNote.length < 10;
    },
    // preferedProviderUpdateValid() {
    //   return this.preferedServiceProvider !== this.goodayUserData.preferedServiceProvider
    //     ? !this.$v.preferedServiceProviderNote.$invalid
    //     : true;
    // },
    readyForProfileUpdate() {
      return this.profileSpellingOk
        && this.profileAgeOk
        && !this.$v.firstNameUpdate.$invalid
        && !this.$v.lastNameUpdate.$invalid
        && !this.$v.ageUpdate.$invalid
        && ((this.firstNameUpdate !== this.goodayUserData.firstName)
          || (this.lastNameUpdate !== this.goodayUserData.lastName)
          || (parseInt(this.ageUpdate, 10) !== this.goodayUserData.age)
          || (this.genderUpdate !== this.goodayUserData.sex)
          || (this.preferedServiceProvider
           !== (this.goodayUserData.preferedServiceProvider === null
             ? false
             : this.goodayUserData.preferedServiceProvider))
          || ((this.editProfilePicture && this.profileImageData !== null)
         || (!this.editProfilePicture))
        )
        && (this.preferedServiceProvider
          ? !this.$v.preferedServiceProviderNote.$invalid === this.preferedServiceProvider
          : true);
    },
    readyForAdUpdate() {
      return this.editAdPicture
        ? this.spellingOk
         && this.serviceCategoryOk
        && !this.$v.updatedGoodayAd.$invalid
        && !this.$v.updatedServices.$invalid
        && !this.$v.adImageData.$invalid
        : this.spellingOk
        && this.serviceCategoryOk
        && !this.$v.updatedGoodayAd.$invalid
        && !this.$v.updatedServices.$invalid;
    },
    readyForDisablingServiceProvider() {
      return this.providerDisableReason !== null
        && this.providerDisableReason !== ''
        && this.disableProviderConfirmation === true;
    },
    readyForLocationUpdate() {
      return this.newLocation !== null
      && this.newLocationConfirm;
    },
    readyForPremiumStatusUpdate() {
      return this.selectedPremiumPackageName !== null
        && this.selectedPaymentMethod !== null
        && this.premiumServicePaidAmount !== null
        && !this.$v.receiptImageData.$invalid
        // eslint-disable-next-line no-restricted-globals
        && !isNaN(parseFloat(this.premiumServicePaidAmount))
        && this.premiumPaymentConfirm
        && this.premiumServicePaidAmount >= this.selectedPackagePaymentAmount;
    },
    gdoAgent() {
      return this.$store.getters['auth/email'];
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      // !this.$v.phone.numeric && errors.push('Phone number can have only numerics')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.phone.required && errors.push('Phone number is required');
      if (!this.$v.phone.minLen || !this.$v.phone.maxLen) errors.push('Phone number should be 13 digits including the + sign');
      return errors;
    },
    accountType() {
      if (this.goodayUserData != null) { return this.goodayUserData.serviceProvider ? 'Service Provider' : 'Regular User'; } return 'Unknown';
    },
    createDate() {
      const cDate = this.goodayUserData.createdAt.toDate();
      const month = cDate.getMonth() + 1;
      const day = cDate.getDate();
      const year = cDate.getFullYear();
      return `${month}/${day}/${year}`;
    },
    updateDate() {
      const cDate = this.goodayUserData.lastUpdate == null ? this.goodayUserData.createdAt.toDate()
        : this.goodayUserData.lastUpdate.toDate();
      const month = cDate.getMonth() + 1;
      const day = cDate.getDate();
      const year = cDate.getFullYear();
      return `${month}/${day}/${year}`;
    },
    recentLocation() {
      return {
        lat: this.goodayUserData.geoLoc.geopoint.latitude,
        lng: this.goodayUserData.geoLoc.geopoint.longitude,
      };
    },
    readyForVerification() {
      return this.firstNameMatch && this.lastNameMatch
       && this.ageMatch && this.genderMatch && this.profilePictureMatch;
    },

    summaryErrors() {
      const errors = [];
      if (!this.$v.updatedGoodayAd.$dirty) return errors;
      /* eslint-disable no-unused-expressions */
      !this.$v.updatedGoodayAd.maxLen && errors.push('Ad Summary can be at most 200 characters long');
      !this.$v.updatedGoodayAd.required && errors.push('Ad Summary is Required');
      return errors;
    },
    serviceErrors() {
      const errors = [];
      if (!this.$v.updatedServices.$dirty) return errors;
      !this.$v.updatedServices.required && errors.push('Select atleast one service');
      return errors;
    },
    AdListingPictureErrors() {
      const errors = [];
      if (!this.$v.adImageData.$dirty) return errors;
      !this.$v.adImageData.required && errors.push('Ad picture is required');
      return errors;
    },
    firstNameEditErrors() {
      const errors = [];
      if (!this.$v.firstNameUpdate.$dirty) return errors;
      !this.$v.firstNameUpdate.required && errors.push('First name is required');
      !this.$v.firstNameUpdate.minLength && errors.push('Name is too short');
      return errors;
    },
    lastNameEditErrors() {
      const errors = [];
      if (!this.$v.lastNameUpdate.$dirty) return errors;
      !this.$v.lastNameUpdate.required && errors.push('Last name is required');
      !this.$v.lastNameUpdate.minLength && errors.push('Name is too short');
      return errors;
    },
    ageUpdateErrors() {
      const errors = [];
      if (!this.$v.ageUpdate.$dirty) return errors;
      !this.$v.ageUpdate.required && errors.push('Age is required');
      !this.$v.ageUpdate.numeric && errors.push('Age should be numeric');
      !this.$v.ageUpdate.minValue && errors.push('Minimum age acceptable');
      !this.$v.ageUpdate.maxValue && errors.push('Maximum age acceptable');
      return errors;
    },
    preferedServiceProviderNoteErrors() {
      const errors = [];
      if (!this.$v.preferedServiceProviderNote.$dirty) return errors;
      !this.$v.preferedServiceProviderNote.required && errors.push('Note is required');
      !this.$v.preferedServiceProviderNote.minLength && errors.push('Note is too short');
      !this.$v.preferedServiceProviderNote.minLength && errors.push('Note is too long');
      return errors;
    },
    fareData() {
      if (this.serviceProviderData.services !== null) {
        const serviceDataList = [];
        this.serviceProviderData.services.forEach((e) => {
          const serviceIndex = this.serviceProviderData.services.indexOf(e);
          const serviceData = {
            service: e,
            preferedEmployment: this.serviceEmploymentTypes[serviceIndex],
            paymentMin: parseFloat(this.serviceMinFares[serviceIndex]),
            paymentMax: parseFloat(this.serviceMaxFares[serviceIndex]),
            experienceYears: this.serviceExperiences[serviceIndex],
          };
          serviceDataList.push(serviceData);
        });
        const timeNow = new Date();
        return {
          services: serviceDataList,
          religionPreference: this.serviceProviderReligionPreference,
          householdPreference: this.serviceProviderHouseHoldPreference,
          locationPreference: this.serviceProviderLocationPreference,
          gdoDataCollector: firebase.auth().currentUser.uid,
          gdoAgentName: this.gdoAgent,
          timeStamp: firestore.Timestamp.fromDate(timeNow),
        };
      }
      return {};
    },
    availableGoodayPartnersIds() {
      const idList = [];
      this.availableGoodayPartners.forEach((p) => idList.push(p.id));
      return idList;
    },
    documentsMap() {
      return [
        { fileName: 'diploma1', fileData: this.diploma1File },
        { fileName: 'diploma2', fileData: this.diploma2File },
        { fileName: 'diploma3', fileData: this.diploma3File },
        { fileName: 'resume', fileData: this.resumeFile },
        { fileName: 'recommendation1', fileData: this.recommendation1File },
        { fileName: 'recommendation2', fileData: this.recommendation2File },
        { fileName: 'recommendation3', fileData: this.recommendation3File },
        { fileName: 'certificate1', fileData: this.certificate1File },
        { fileName: 'certificate2', fileData: this.certificate2File },
        { fileName: 'certificate3', fileData: this.certificate3File },
      ];
    },
    adIsDisabled() {
      return this.serviceProviderData == null ? false
        : (this.serviceProviderData.services.length === 0
       && this.serviceProviderData.gdoDisabledAd != null);
    },
    paymentPackages() {
      const packageList = [];
      this.paymentConfiguration.forEach((p) => packageList.push(p.name));
      return packageList;
    },
    selectedPackagePaymentAmount() {
      const selectedPackage = this.paymentConfiguration
        .find((p) => p.name === this.selectedPremiumPackageName);
      return selectedPackage.amount * (1 - selectedPackage.discount);
    },
  },
  created() {
    this.$store.dispatch('auth/persistSession');
    this.getServices();
    this.getPaymentConfiguration();
    this.getGoodayTrainingPartners();
  },
};
</script>

<style scoped>
.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}
.selected-service {
  color: #E21E81;
}
.footer-note {
  color:#0f1ccd;
  font-size: 4;
}
.image-section {
  background-color: #E21E81;
}
</style>
