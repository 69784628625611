import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import Home from '../components/Home.vue';
import PhoneSignIn from '../components/PhoneSignIn.vue';
import Onboard from '../components/Onboard.vue';
import Dashboard from '../components/Dashboard.vue';
import Inspection from '../components/Inspection.vue';
import Complaint from '../components/Complaint.vue';
import Engagement from '../components/Engagement.vue';
import FollowUp from '../components/FollowUp.vue';
import AccountDelete from '../components/AccountDelete.vue';
import CallCenter from '../components/CallCenter.vue';
import Dispatch from '../components/Dispatch.vue';
import Intake from '../components/Intake.vue';
import Notification from '../components/SMSNotification.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: PhoneSignIn,
  },
  {
    path: '/callcenter',
    name: 'callcenter',
    component: CallCenter,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    component: Dispatch,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/intake',
    name: 'intake',
    component: Intake,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/onboard',
    name: 'onboard',
    component: Onboard,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/inspect',
    name: 'inspect',
    component: Inspection,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/complaint',
    name: 'complaint',
    component: Complaint,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/accountdelete',
    name: 'accountdelete',
    component: AccountDelete,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/engagement',
    name: 'engagement',
    component: Engagement,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/followup',
    name: 'followup',
    component: FollowUp,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/notification',
    name: 'notification',
    component: Notification,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next('/');
    },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
//   {
//     path: '/myaccount',
//     name: 'settings',
//     component: MyAccount,
//     beforeEnter: (to, from, next) => {
//       if (firebase.auth().currentUser) next();
//       else next('/signin');
//     },
//   },
//   {
//     path: '/feed',
//     component: Feed,
//     name: 'feed',
//     beforeEnter: (to, from, next) => {
//       if (firebase.auth().currentUser) next();
//       else next('/signin');
//     },
//   },
//   {
//     path: '/post',
//     component: Post,
//     beforeEnter: (to, from, next) => {
//       if (firebase.auth().currentUser) next();
//       else next('/signin');
//     },
//   },
//   {
//     path: '/',
//     name: 'home',
//     component: GMap,
//   },
  // {
  //     path: '/cropper',
  //     component: Cropping,
  // },
];

export default new VueRouter({ mode: 'history', routes });
