/* eslint-disable import/prefer-default-export */
export const availableServices = [
  'Cooking Maid',
  'Cleaning Maid',
  'Nanny',
  'Chauffeur',
  'Tutor',
  'Satellite Dish Tech',
  'Auto Mechanic',
  'Computer Tech',
  'Cellphone Tech',
  'Handyman',
  'Broker',
  'Errand Runner',
  'Nurse',
  'Moving Truck',
  'Tow Truck',
  'Beauty',
  'Massage',
  'Shoe Repair',
  'Shoe Cleaner',
  'Hair Stylist',
  'Barber',
  'Tailor',
  'Painter',
  'Construction',
  'Weaver',
  'Catering',
  'Waitering',
  'Salesman',
  'Gardener',
  'Cameraman',
  'Security Guard',
  'Laborer',
  'Translator',
  'Machine Operator',
  'Electrician',
  'Carpenter',
  'Teacher',
  'Tattoo Artist',
  'Music Teacher',
  'Motorcyclist',
  'Locksmith',
  'School Bus',
  'Personal Trainer',
  'Welding',
  'DJ',
  'Senior Care',
  'Machine Installation',
  'Machine Maintenance',
  'Language Teacher',
  'Special Needs',
  'Physiotherapy',
  'Physician',
  'Knitting',
  'Embroidery',
  'Decor',
  'Event Organizer',
  'Accountant',
  'Plumber',
];
