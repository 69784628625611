<template>
   <v-card width="400px" class="mt-5 mx-auto" color="grey darken-3" >
       <v-card-title class="pb-0">
           <h3 style="color: white;">GO Admin Login</h3>
       </v-card-title>
       <v-spacer></v-spacer>
       <v-card-text >
           <v-form>
               <v-text-field
               label="GO Admin phone number"
               prepend-icon="mdi-cellphone"
               v-model="phone"
               dark
               :error-messages="phoneErrors"
               required
               @input="$v.phone.$touch()"
               @blur="$v.phone.$touch()"/>
               <span
               v-if="!phoneExists && okSignin && !showVerifyCode"
               style="color: #D500F9; font-size:12px">
               <v-icon small color="#D500F9">mdi-information-outline</v-icon>
               Tap Verify and wait for sign-in SMS code
               </span>
               <v-progress-linear
                   v-if="okSignin && !phoneExists && !showVerifyCode"
                   indeterminate
                   rounded
                   height="6"
                   color="purple accent-3">
               </v-progress-linear>
               <span
               v-if="!phoneExists && okSignin && phoneSearched"
               style="color: #FFC107; font-size:12px">
               <v-icon small color="amber">mdi-alert</v-icon>
               This phone number is new to gdoadm system, contact admin for access !
               </span>
               <v-text-field
               v-if="showVerifyCode"
               label="Enter the SMS verification code you received"
               v-model="verifyCode"
               dark></v-text-field>
               <v-text-field
               v-if="showVerifyCode"
               label="Admin Pin Code"
               v-model="badgeNumber"
               :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
               @click:append="() => (value = !value)"
               :type="value ? 'password' : 'text'"
               dark></v-text-field>
               <span
               v-if="smsFailed"
               style="color: #FFC107; font-size:12px">
               <v-icon small color="#FFC107">mdi-alert</v-icon>
               SMS verification failed, check your phone number and try again
               </span>
           </v-form>
           <p
           v-if="verifyError"
           style="color: #FFC107; font-size:12px">Invalid SMS token, request a token again!</p>
       </v-card-text>
       <v-card-actions>
           <v-btn
           v-if="!showVerifyCode"
           color="white"
           type="submit"
           v-on:submit.prevent="smsAuthenticate"
           @click="smsAuthenticate"
           id="sign-in-button"
           :disabled="$v.$invalid">Verify Phone</v-btn>
           <v-btn
           v-if="showVerifyCode"
           color="white"
           @click="signIn" >Sign In</v-btn>
       </v-card-actions>
   </v-card>
</template>

<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { firebaseAuthMixins } from './mixins/firebaseAuth';

export default {
  mixins: [
    firebaseAuthMixins,
  ],
  validations: {
    phone: {
      required,
      // numeric,
      minLen: minLength(13),
      maxLen: maxLength(13),
    },
  },
  data() {
    return {
      // password: '',
      // showPassword: false,
      badgeNumber: null,
      value: String,
    };
  },
  methods: {
    smsAuthenticate() {
      this.$store.dispatch('auth/smsAuthenticate', this.phone);
    },
    signIn() {
      this.$store.dispatch('auth/signIn', { verifyCode: this.verifyCode, badgeNumber: this.badgeNumber });
    },
  },
  computed: {
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      // !this.$v.phone.numeric && errors.push('Phone number can have only numerics')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.phone.required && errors.push('Phone number is required');
      if (!this.$v.phone.minLen || !this.$v.phone.maxLen) errors.push('Phone number should be 13 digits including the + sign');
      return errors;
    },
    okSignin() {
      return !this.$v.$invalid;
    },
    // am() {
    //   // eslint-disable-next-line no-console
    //   // console.log("locale is :", this.$i18n.locale)
    //   return this.$i18n.locale === 'አማ';
    // },
  },
  //   watch: {
  //     user() {
  //       if (this.$store.getters['auth/userId'] && this.badgeNumber) {
  //         this.$store.dispatch('auth/signInRouter', this.badgeNumber);
  //       }
  //     },
  //   },

};
</script>
