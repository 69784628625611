<template>
<v-main>
   <v-row>
        <v-card
            width="700px"
            height="300px"
            class="mt-2 mx-auto"
            color="grey darken-3"
            elevation="10">
            <v-card-title class="pb-0">
                <h3 style="color: white">Customer Lookup</h3>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
                <v-form>
                    <v-text-field
                        label="calling customer phone number"
                        prepend-icon="mdi-cellphone"
                        v-model="phoneNumber"
                        dark
                        :error-messages="phoneNumberErrors()"
                        required
                        @input="$v.phoneNumber.$touch()"
                        @blur="$v.phoneNumber.$touch()"/>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    class="mr-10"
                    :disabled="$v.phoneNumber.$invalid"
                    dark
                    @click="customerLookUp">
                    <v-icon color="#E21E81">mdi-account-search</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
   </v-row>
      <v-row>
        <v-card
          width="700px"
          height="850px"
          class="mt-2 mx-auto mb-10"
          color="grey darken-3"
          v-if="customerLookedUp"
          elevation="10">
          <v-card-title class="pb-0">
            <h3 style="color: white">Customer Information</h3>
          </v-card-title>
          <v-card-text v-if="customerLookedUp">
            <v-form v-model="formValid">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="customerFirstName"
                      :rules="nameRules"
                      :counter="10"
                      label="First Name"
                      required
                      dark
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="customerLastName"
                      :rules="nameRules"
                      :counter="10"
                      label="Last Name"
                      required
                      dark
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerHouseHoldList"
                      v-model="customerHouseHold"
                      label="Size of Household"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerResidenceList"
                      v-model="customerResidence"
                      label="Residence Type"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerResidenceOwnershipList"
                      v-model="customerResidenceOwnership"
                      label="Home Owner"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="customerReligionPreferenceList"
                      v-model="customerReligionPreference"
                      label="Religion Preference"
                      dense
                      dark
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-if="callCount > 0">
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <h4 style="color: white">previous calls</h4>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                     <h1 style="color: white">{{ callCount }}</h1>
                  </v-col>
                  <v-col
                    v-if="appUserCustomer"
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <v-icon color="#e21e81">mdi-cellphone-play</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-else>
                  <v-col
                    v-if="appUserCustomer"
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <v-icon color="#e21e81">mdi-cellphone-play</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-if="callCount > 0">
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <h4 style="color: white">last request location</h4>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                     <h4 style="color: white">
                       {{ lastRequestLocation }}
                     </h4>
                  </v-col>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters v-if="callCount > 0">
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                    <h4 style="color: white">last request service</h4>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="10"
                    md="3"
                  >
                     <h4 style="color: white">
                       {{ lastRequestService }}
                     </h4>
                  </v-col>
                </v-row>
                <v-container></v-container>
                <v-row class="justify-center">
                    <v-card-title class="pb-0">
                    <h3 style="color: white">New Request</h3>
                </v-card-title>
                </v-row>
                <v-row align="center" class="pa-0" no-gutters>
                <v-col dense>
                  <v-menu :rounded="true" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        color="#e21e81"
                        width="300px"
                        class="white--text my-1 py-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Select Category
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="c in services" :key="c.category" link>
                        <v-list-item-title
                          v-text="c.category"
                          @click="selectCategory(c.category)"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col dense>
                  <v-card color="lime" width="280px">
                    <v-card-text class="my-1 py-2">
                      {{ queryCategory }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <v-col>
                  <v-menu :rounded="true" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        color="#e21e81"
                        width="300px"
                        class="white--text my-1 py-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Select Service
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="s in queryServiceList" :key="s" link>
                        <v-list-item-title
                          v-text="s"
                          @click="selectService(s)"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col dense>
                  <v-card color="lime" width="280px">
                    <v-card-text class="my-1 py-2">
                      {{ queryService }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <v-col>
                  <v-menu :rounded="true" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        color="#e21e81"
                        width="300px"
                        class="white--text my-1 py-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Select type of hiring
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="t in customerHireDemandList" :key="t" link>
                        <v-list-item-title
                          v-text="t"
                          @click="selectHiringType(t)"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col dense>
                  <v-card color="lime" width="280px">
                    <v-card-text class="my-1 py-2">
                      {{ customerHireDemand }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="my-1 py-1 justify-start" no-gutters>
                  <v-card color="#e21e81" width="600px">
                    <v-card-text class="my-1 py-2">
                      <VueGooglePlaces
                        id="map"
                        :options="{ fields: ['geometry'] }"
                        types="establishment"
                        country="et"
                        @placechanged="getAddressData"
                        style="width: 100%"
                        label=""
                      >
                        <v-text-field
                          dense
                          dark
                          hide-details=""
                          class="ma-0 pt-1"
                          label="Enter Customer's location"
                        />
                      </VueGooglePlaces>
                    </v-card-text>
                  </v-card>
              </v-row>
              <v-row >
                  <v-container width="400px">
                  <v-textarea
                    label="Add detailed requirements of customer"
                    outlined
                    background-color="grey darken-1"
                    dark
                    rows="5"
                    v-model="newRequestDescription"
                    :rules="descriptionRules"
                    required
                  ></v-textarea>
                  </v-container>
              </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center mt-0 pt-0">
            <v-btn
              dense
              color="#E21E81"
              dark
              :disabled="!readyForIntake"
              @click="submitIntake">Submit For Dispatch</v-btn>
            <v-spacer></v-spacer>
            <v-btn dense color="#E21E81" dark>Cancel</v-btn>
          </v-card-actions>
        </v-card>
        </v-row>
        <v-dialog v-model="inTakeInProgress" max-width="600px">
       <v-card
        width="900px"
        height="100px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10">
          <v-card-text>
            <h2 style="color: white" class="my-4 mx-auto">
              Sending to dispatch queue ...</h2>
          </v-card-text>
          <v-progress-linear
            indeterminate
            height="20"
            striped
            color="#e21e81"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
</v-main>
</template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import firebase, { firestore } from 'firebase/app';
import axios from 'axios';

export default {
  data() {
    return {
      placesAccess: process.env.VUE_APP_PLACES_APIKEY,
      center: { lat: 9.005401, lng: 38.763611 },
      phoneNumber: '+251',
      newCustomer: false,
      customerLookupInProgress: false,
      appUserCustomer: false,
      customerFirstName: '',
      customerLastName: '',
      customerRequestTrackingId: null,
      calls: null,
      callCount: 0,
      lastRequestService: null,
      lastRequestLocation: null,
      lastRequestDate: null,
      lastRequestProviders: [],
      customerHouseHold: 'undisclosed',
      customerResidence: 'undisclosed',
      customerResidenceOwnership: 'undisclosed',
      customerReligionPreference: 'undisclosed',
      customerHireDemand: null,
      // requestBudgetMin: 0,
      // requestBudgetMax: 0,
      lastRequestData: [],
      customerLookedUp: false,
      customerHouseHoldList: ['Single', 'Couple', 'Family', 'undisclosed'],
      customerResidenceList: ['Private Compound', 'Condominium', 'Apartment', 'undisclosed'],
      customerResidenceOwnershipList: ['Rent', 'Own', 'undisclosed'],
      customerHireDemandList: ['any', 'monthly (resident)', 'monthly (outcall)', 'weekly', 'daily', 'hourly', 'task based'],
      customerReligionPreferenceList: ['any', 'Christian', 'Muslim', 'undisclosed'],
      formValid: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      descriptionRules: [
        (v) => !!v || 'Detailed description of employer\'s service requirement is required',
        (v) => v.length > 10 || 'Please add the detailed requirement of the employer',
      ],
      services: null,
      queryCategory: null,
      queryServiceList: [],
      queryService: null,
      queryLocation: null,
      nearbyLocation: null,
      newRequestDescription: '',
      inTakeInProgress: false,
    };
  },
  validations: {
    phoneNumber: {
      required,
      minLen: minLength(13),
      maxLen: maxLength(13),
    },
  },
  methods: {
    customerLookUp() {
      this.$store.dispatch('auth/persistSession');
      const db = firebase.firestore();
      this.customerLookupInProgress = true;
      // first check if caller is an app user
      // then check if caller is a new caller
      // for app users get profile data from users collection
      // for non-app users get profile data from cc_requests collection
      db.collection('users')
        .where('phoneNumber', '==', this.phoneNumber)
        .get()
        .then((userSnapshot) => {
          // console.log(userSnapshot.docs[0].data());
          if (userSnapshot.empty) {
            // console.log(userSnapshot.docs[0].data());
            this.appUserCustomer = false;
          } else {
            this.appUserCustomer = true;
            this.customerFirstName = userSnapshot.docs[0].data().firstName;
            this.customerLastName = userSnapshot.docs[0].data().lastName;
          }
          db.collection('cc_requests')
            .where('phoneNumber', '==', this.phoneNumber)
            .get()
            .then((ccRecSnapshot) => {
              if (!ccRecSnapshot.empty) {
                this.newCustomer = false;
                const customerInfo = ccRecSnapshot.docs[0].data();
                console.log(ccRecSnapshot.docs[0].id);
                console.log(customerInfo);
                // this.customerRequestTrackingId = ccRecSnapshot.docs[0].id;
                // calls field is checked for old data format - cc_request collection fields
                // got changed during integration to external crm system
                this.calls = customerInfo.calls;
                this.callCount = ccRecSnapshot.docs.length;
                this.lastRequestService = customerInfo.requestedService
                  ? customerInfo.requestedService
                  : this.calls[this.calls.length - 1].requestedService;
                this.lastRequestLocation = customerInfo.requestLocation != null
                  ? customerInfo.requestLocation.name
                  : this.calls[this.calls.length - 1].requestLocation.name;
                // this.customerBudgetMin = customerInfo.requestBudgetMin
                //   ? customerInfo.requestBudgetMin
                //   : this.calls[this.calls.length - 1].requestBudgetMin;
                // this.customerBudgetMax = customerInfo.requestBudgetMax
                //   ? customerInfo.requestBudgetMax
                //   : this.calls[this.calls.length - 1].requestBudgetMax;
                this.lastRequestDate = customerInfo.timeStamp
                  ? this.dateFormat(customerInfo.timeStamp)
                  : this.dateFormat(this.calls[this.calls.length - 1].timeStamp);
                // this.lastRequestProviders = this.calls[
                //   this.calls.length - 1
                // ].serviceProvidersSupplied;
                this.customerFirstName = customerInfo.firstName;
                this.customerLastName = customerInfo.lastName;
                this.customerHouseHold = customerInfo.houseHoldSize;
                this.customerResidence = customerInfo.residenceType;
                this.customerResidenceOwnership = customerInfo.homeOwner;
                this.customerReligionPreference = customerInfo.religionPreference;
                // this.lastRequestData = [
                //   {
                //     lastRequestedService: this.lastRequestService,
                //     lastRequestLocation: this.lastRequestLocation,
                //     lastRequestDate: this.lastRequestDate,
                //     totalNumberOfCalls: this.callCount,
                //     appUser: this.appUserCustomer,
                //     customerBudgetMin,
                //     customerBudgetMax,
                //   },
                // ];
              } else {
                this.newCustomer = true;
              }
              this.customerLookedUp = true;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      // !this.$v.phone.numeric && errors.push('Phone number can have only numerics')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.phoneNumber.required && errors.push('Phone number is required');
      if (!this.$v.phoneNumber.minLen || !this.$v.phoneNumber.maxLen) {
        errors.push('Phone number should be 13 digits including the + sign');
      }
      return errors;
    },
    dateFormat(date) {
      const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      // eslint-disable-next-line prefer-template
      return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    },
    jiraDateFormat(dd) {
      // 2022-03-26T19:33:05-0400
      // const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      const h = dd.getHours();
      const M = dd.getMinutes();
      return `${y}-${m <= 9 ? `0${m}` : m
      }-${d <= 9 ? `0${d}` : d}T${h <= 9 ? `0${h}` : h
      }:${M <= 9 ? `0${M}` : M}`;
    },
    selectCategory(category) {
      this.queryCategory = category;
      this.queryServiceList = this.services.find(
        (c) => c.category === category,
      ).services;
      this.queryService = null;
    },
    selectService(service) {
      this.queryService = service;
    },
    selectHiringType(t) {
      this.customerHireDemand = t;
    },
    getAddressData(addressData) {
      // console.log(addressData);
      this.center = { lat: addressData.latitude, lng: addressData.longitude };
      this.queryLocation = addressData;
      this.nearbyLocation = addressData.formatted_address;
    },
    getServices() {
      const db = firebase.firestore();
      db.collection('services')
        .doc('gooday_classified_services')
        .get()
        .then((servicesSnapshot) => {
          this.services = servicesSnapshot.data().services;
        })
        .catch((error) => console.log(error));
    },
    resetQuery() {
      this.phoneNumber = '+251';
      this.newCustomer = false;
      this.customerLookupInProgress = false;
      this.appUserCustomer = false;
      this.customerFirstName = '';
      this.customerLastName = '';
      this.customerRequestTrackingId = null;
      this.calls = null;
      this.callCount = 0;
      this.lastRequestService = null;
      this.lastRequestLocation = null;
      this.lastRequestDate = null;
      this.lastRequestProviders = [];
      this.customerHouseHold = 'undisclosed';
      this.customerResidence = 'undisclosed';
      this.customerResidenceOwnership = 'undisclosed';
      this.customerReligionPreference = 'undisclosed';
      this.lastRequestData = [];
      this.customerLookedUp = false;
      this.queryCategory = null;
      this.queryServiceList = [];
      this.queryService = null;
      this.queryLocation = null;
      this.nearbyLocation = null;
      this.newRequestDescription = '';
      // this.requestBudgetMin = 0;
      // this.requestBudgetMax = 0;
      this.customerHireDemand = null;
    },
    submitIntake() {
      // const db = firebase.firestore();
      this.inTakeInProgress = true;
      const timeNow = new Date();
      const db = firebase.firestore();
      const crmPayLoad = {
        service: this.queryService,
        employername: `${this.customerFirstName} ${this.customerLastName}`,
        employer: this.phoneNumber,
        description: this.newRequestDescription,
        employerlocation: this.queryLocation.name,
        servicerequesttime: this.jiraDateFormat(timeNow),
        intakeagent: this.gdoAgent,
        mode: 'intake',
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      console.log(crmPayLoad);
      axios.post(process.env.VUE_APP_GCRM_URL, crmPayLoad, config)
        .then((resp) => {
          console.log(resp.data);
          // use jira case number to record data in cc_requests
          if (resp == null) {
            // eslint-disable-next-line no-alert
            alert('failed to create CRM tracking case');
          } else {
            const customerRequestData = {
              firstName: this.customerFirstName,
              lastName: this.customerLastName,
              phoneNumber: this.phoneNumber,
              appUser: this.appUserCustomer,
              houseHoldSize: this.customerHouseHold,
              residenceType: this.customerResidence,
              homeOwner: this.customerResidenceOwnership,
              nearbyLocation: this.nearbyLocation,
              religionPreference: this.customerReligionPreference,
              callId: resp.data.key,
              // requestBudgetMin: this.customerBudgetMin,
              // requestBudgetMax: this.customerBudgetMax,
              requestHiringType: this.customerHireDemand,
              requestedService: this.queryService,
              timeStamp: firestore.Timestamp.fromDate(timeNow),
              callCenterAgent: this.gdoAgent,
              requestLocation: {
                administrativeAreaLevel1: this.queryLocation.administrative_area_level_1,
                country: this.queryLocation.country,
                countryCode: this.queryLocation.country_code,
                formattedAddress: this.queryLocation.formatted_address,
                latitude: this.queryLocation.latitude,
                locality: this.queryLocation.locality,
                longitude: this.queryLocation.longitude,
                name: this.queryLocation.name,
                placeId: this.queryLocation.place_id,
              },
            };
            db.collection('cc_requests')
              .add(customerRequestData)
              .then((response) => {
                console.log(response.id);
                this.resetQuery();
                this.inTakeInProgress = false;
              })
              .catch((error) => {
                console.log(error);
                this.resetQuery();
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.resetQuery();
        });
    },
  },
  computed: {
    gdoAgent() {
      return this.$store.getters['auth/email'];
    },
    readyForIntake() {
      return (
        this.customerFirstName !== ''
        && this.customerLastName !== ''
        && this.queryCategory !== null
        && this.queryService !== null
        && this.customerHireDemand !== null
        && this.queryLocation !== null
        && this.newRequestDescription !== ''
        && this.formValid
      );
    },
  },
  created() {
    this.$store.dispatch('auth/persistSession');
    this.getServices();
  },
};
</script>
