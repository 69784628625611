/* eslint-disable quotes */
<template>
  <div>
    <v-navigation-drawer v-model="sidebar" app disable-resize-watcher dark>
      <v-list>
        <v-list-item>
          <v-list-item-content>Gooday</v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="sidebar =!sidebar">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item>
        <v-list v-if="!authed">
          <v-list-item
            v-for="(item,index) in outMenuItems"
            :key="index"
            :to="{ name: item.link }"
            exact
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
            v-for="(item,index) in inMenuItems"
            :key="index"
            :to="{ name: item.link }"
            exact
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list-item v-if="authed" @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>SignOut
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#c21e81">
      <span class="hidden-md-and-up">
        <v-app-bar-nav-icon x-large @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>
      <div class="d-flex align-left">
        <v-img
          alt="Gooday Logo"
          class="shrink my-1"
          contain
          src="../assets/Banner-Gooday-Cloud-TM-logo.png"
          transition="scale-transition"
          width="50"
        />
      </div>
      <v-spacer></v-spacer>

      <v-btn  class="ma-1 hidden-sm-and-down" to="/">
        <v-icon left>mdi-home</v-icon>
        Home
      </v-btn>
      <!-- <v-btn v-if="authed"  class="ma-1 hidden-sm-and-down" to="/dashboard">
        <v-icon left>mdi-account-plus</v-icon>
        Dashboard
      </v-btn> -->
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/intake">
        <v-icon left>mdi-headset</v-icon>
        Intake
      </v-btn>
        <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/dispatch">
        <v-icon left>mdi-cube-send</v-icon>
        Dispatch
      </v-btn>
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/callcenter">
        <v-icon left>mdi-bullseye</v-icon>
        Call Center
      </v-btn>
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/inspect">
        <v-icon left>mdi-compass</v-icon>
        Inspect
      </v-btn>
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/followup">
        <v-icon left>mdi-reminder</v-icon>
        Follow Up
      </v-btn>
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/accountdelete">
        <v-icon left>mdi-delete-clock</v-icon>
        Account Delete
      </v-btn>
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/notification">
        <v-icon left>mdi-message-text-fast</v-icon>
        Notification
      </v-btn>
      <!-- <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/newappusers">
        <v-icon left>mdi-user</v-icon>
        New Users
      </v-btn> -->
      <!-- <v-btn v-if="authed && adminUser"
      class="ma-1 hidden-sm-and-down" to="/engagement">
        <v-icon left>mdi-human-greeting-proximity</v-icon>
        Engagement
      </v-btn> -->
      <v-btn v-if="authed"
      class="ma-1 hidden-sm-and-down" to="/complaint">
        <v-icon left>mdi-alert-octagram</v-icon>
        Reported Providers
      </v-btn>
      <v-btn v-if="!authed"  class="ma-1 hidden-sm-and-down" to="/signin">
        <v-icon left>mdi-login</v-icon>
        SignIn
      </v-btn>
      <v-btn
        v-if="authed"
        class="ma-1 hidden-sm-and-down"
        @click="signOut"
      >
        <v-icon left>mdi-logout</v-icon>
        SignOut
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {

    authed() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    outMenuItems() {
      return [
        {
          title: 'signin',
          link: 'signin',
          icon: 'mdi-login',
        },
      ];
    },
    agent() {
      return this.$store.getters['auth/email'];
    },
    inMenuItems() {
      return [
        {
          title: 'followup',
          link: 'followup',
          icon: 'mdi-reminder',
        },
      ];
    },
  },
  methods: {
    test() {},
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    signOut() {
      this.$store.dispatch('auth/signOut');
    },
  },
  data: () => ({
    sidebar: false,
  }),
  created() {
    this.$store.dispatch('auth/persistSession');
  },
};
</script>
